import Api from "../Api";

export const createEnquiry = (data) => {
  return Api.post(`/create-admission-enquery`, data);
};
export const getAllEnquirys = (params) => {
  return Api.get("/get-admission-enquerys", {
    params,
  });
};
export const getEnquiryById = (id) => {
  return Api.get(`/get-admission-enquery/${id}`);
};
export const updateEnquiryById = (id, data) => {
  return Api.post(`/update-admission-enquery/${id}`, data);
};
export const deleteEnquiryById = (id) => {
  return Api.delete(`/delete-admission-enquery/${id}`);
};
