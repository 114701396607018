import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import { useParams } from "react-router-dom";
import { ErrorMessage, Field, Formik } from "formik";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import * as Yup from "yup";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { getAcademicPayments } from "../../Services/CommonApis/FeesApi";

const AcademicTransaction = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { branchId } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedClassId, setClassId] = useState(null);
  const [selectedSectionId, setSectionId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [isClassLoading, setClassLoading] = useState(false);
  const [isSectionLoading, setSectionLoading] = useState(false);
  const [isStudentLoading, setIsStudentLoading] = useState(false);

  useEffect(() => {
    fetchClassByBranchId(branchId);
  }, [branchId]);

  useEffect(() => {
    if (selectedClassId) {
      fetchSectionByClassId(selectedClassId);
      setSectionId(null);
      setSectionList([]);
      setSelectedStudentId(null);
      setStudentList([]);
    }
  }, [selectedClassId]);

  useEffect(() => {
    if (selectedSectionId) {
      fetchStudentsByClassSectionId(
        branchId,
        selectedClassId,
        selectedSectionId
      );
    }
  }, [selectedSectionId]);

  useEffect(() => {
    setShowTable(selectedClassId && selectedSectionId && selectedStudentId);
  }, [selectedClassId, selectedSectionId, selectedStudentId]);

  const fetchClassByBranchId = async (branchId) => {
    try {
      setClassLoading(true);
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setClassLoading(false);
    }
  };

  const fetchSectionByClassId = async (classId) => {
    try {
      setSectionLoading(true);
      const response = await getSectionByClassId(classId);
      setSectionList(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    } finally {
      setSectionLoading(false);
    }
  };

  const fetchStudentsByClassSectionId = async (
    branchId,
    classId,
    sectionId
  ) => {
    try {
      setIsStudentLoading(true);
      const response = await getstudentbyclassandsection(
        branchId,
        classId,
        sectionId
      );
      setStudentList(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsStudentLoading(false);
    }
  };

  useEffect(() => {
    if (showTable && selectedStudentId) {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "fees_details", title: "Fee Details" },
          { data: "amount_to_pay", title: "Amount to pay" },
          { data: "amount_paid", title: "Amount Paid" },
          { data: "balance", title: "Balance" },
          {data:"payment_date", title:"Payment Paid Date"},
          {
            data: "paid_status",
            title: "Status",
            render: function (data, type, row) {
              if (data.toLowerCase() === "paid") {
                return '<span style="color: green; font-weight: bold;">Paid</span>';
              } else {
                return '<span style="color: red; font-weight: bold;">Unpaid</span>';
              }
            },
          },
        ],
        lengthChange: true,
        order:true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Subject Found Based On The Selected Class & Section",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "desc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getAcademicPayments({
              branch_id: branchId,
              student_id: selectedStudentId,
              page: page,
              _limit: length,
              _sort: orderColumn,
              _order: orderDirection,
              q: searchValue,
            });

            const jsonData = response.data.data || [];
            const totalCount = response.data.total || 0;

            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            if (error.response && error.response.status === 404) {
              showErrorAlert("No Data Found.");
            } else {
              showErrorAlert("No response from the server. Please try again.");
            }
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });

      return () => {
        if (tableInstance.current) {
          tableInstance.current.destroy(true);
        }
      };
    }
  }, [selectedStudentId, showTable]);

  const initialValues = {
    class_id: "",
    section_id: "",
    student_id: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
    student_id: Yup.string().required("Section is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Fee Transaction</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Fee Transaction</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Fee Transaction Table
          </h3>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values }) => (
            <div className="card-body">
              <div className="row">
                <div className="form-group col-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="class_id"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const selectedClassId = e.target.value;
                      setFieldValue("class_id", selectedClassId);
                      setClassId(selectedClassId);
                    }}
                  >
                    {isClassLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Class</option>
                        {classList.map((cls) => (
                          <option key={cls.id} value={cls.id}>
                            {cls.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="section_id">
                    Section Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="section_id"
                    name="section_id"
                    className="form-control"
                    value={selectedSectionId || ""}
                    onChange={(e) => {
                      const selectedSectionId = e.target.value;
                      setFieldValue("section_id", selectedSectionId);
                      setSectionId(selectedSectionId);
                    }}
                    disabled={!selectedClassId}
                  >
                    {isSectionLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Section</option>
                        {sectionList.map((section) => (
                          <option key={section.id} value={section.id}>
                            {section.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="section_id"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="student_id">
                    Student Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="student_id"
                    name="student_id"
                    className="form-control"
                    value={selectedStudentId || ""}
                    onChange={(e) => {
                      const selectedStudentId = e.target.value;
                      setFieldValue("student_id", selectedStudentId);
                      setSelectedStudentId(selectedStudentId);
                    }}
                    disabled={!selectedSectionId}
                  >
                    {isStudentLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Student</option>
                        {studentList.map((student) => (
                          <option key={student.id} value={student.id}>
                            {student.first_name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="section_id"
                    component="div"
                    className="text-error"
                  />
                </div>
              </div>

              {showTable ? (
                <div className="row">
                  <div className="col-12">
                    <table
                      ref={tableRef}
                      className="table table-striped table-bordered"
                      style={{ width: "100%" }}
                    ></table>
                  </div>
                </div>
              ) : (
                <div className="alert alert-info">
                  Please select both class, section & student to view the
                  payments list.
                </div>
              )}
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AcademicTransaction;
