import Api from "../Api";

export const createClass = async (data) => {
  console.log("data", data);
  return await Api.post(`/create-class`, data);
};

export const getClasses = async (id) => {
  return await Api.post(`/getall-classes/${id}`);
};
export const getClassByBranchId = async (id) => {
  return await Api.get(`/get-classes-branch/${id}`);
};

export const deleteClassById = async (id) => {
  return await Api.get(`/delete-class/${id}`);
};
export const updateClassById = async (id, data) => {
  return await Api.post(`/update-class/${id}`, data);
};
export const getClassById = async (id) => {
  return await Api.get(`/get-class/${id}`);
};

export const uploadClass = async (branchId, formData) => {
  formData.append("branch_id", branchId);

  return Api.post("/upload-class", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getClassSectionbyBranchId = async (id) => {
  return await Api.get(`/get-class-section/${id}`);
};
