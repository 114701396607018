import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../Styles/SchoolUpload.css";
import { StudentExcelValidation } from "../../Utility/AdminUtility/AdminValidation";
import {
  showSweetAlert,
  showLoadingAlert,
  showSuccessAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import handleDownloadStudentExcel from "../../Utility/AdminUtility/StudentExcel";
import { uploadStudent } from "../../Services/CommonApis/StudentApi";
import { Field, ErrorMessage, Formik, Form } from "formik";

import * as Yup from "yup";

const StudentUpload = () => {
  const { branchId } = useParams();
  const [fileName, setFileName] = useState("No file chosen");
  const [file, setFile] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [message, setMessage] = useState({ type: "", content: "" });
  const [validationErrors, setValidationErrors] = useState([]);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [parentPhoneErrors, setParentPhoneErrors] = useState([]);
  const [confirmation, setConfirmation] = useState("");
  const [alertErrorMessage, setAlertErrorMessage] = useState("");

  useEffect(() => {
    fetchClasses(branchId);
  }, []);

  useEffect(() => {
    if (message.content) {
      const duration = 3000;
      const fadeDuration = 500;

      const showTimer = setTimeout(() => {
        setMessage((prev) => ({
          ...prev,
          type: "fade-out",
        }));
      }, duration);

      const clearTimer = setTimeout(() => {
        setMessage({ type: "", content: "" });
      }, duration + fadeDuration);

      return () => {
        clearTimeout(showTimer);
        clearTimeout(clearTimer);
      };
    }
  }, [message]);

  const fetchClasses = async () => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileName(selectedFile ? selectedFile.name : "No file chosen");
    setMessage({ type: "", content: "" });
    setAlertErrorMessage("");
    setParentPhoneErrors("");
    handleShowData(selectedFile);
    setFile(selectedFile);
  };

  const handleShowData = (selectedFile) => {
    if (!selectedFile) {
      setMessage({ type: "error", content: "No file selected" });
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const errors = StudentExcelValidation(jsonData);

      if (errors.length === 0) {
        if (selectedClass && selectedSection) {
          setIsUploadEnabled(true);
          setMessage({
            type: "confirmation",
            content: "Please upload data by clicking upload button",
          });
        } else {
          setIsUploadEnabled(false);
          setMessage({
            type: "error",
            content: "Class and section must be selected before upload.",
          });
        }
        setTableData(jsonData);
        setValidationErrors([]);
      } else {
        const phoneErrors = errors.filter((error) => error.sharedParentPhone);
        const otherErrors = errors.filter((error) => !error.sharedParentPhone);

        setParentPhoneErrors(phoneErrors);
        setValidationErrors(otherErrors);

        setTableData(jsonData);

        if (otherErrors.length === 0) {
          if (selectedClass && selectedSection) {
            setIsUploadEnabled(true);
            setMessage({
              type: "error",
              content:
                "Parent phone validation issues found, but upload is allowed.",
            });
          } else {
            setIsUploadEnabled(false);
            setMessage({
              type: "error",
              content: "Class and section must be selected before upload.",
            });
          }
        } else {
          // Other errors present, disable upload
          setIsUploadEnabled(false);
          setMessage({
            type: "error",
            content: "Data validation failed. Please check the file.",
          });
        }
      }
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  const handleClear = () => {
    setFileName("No file chosen");
    setTableData([]);
    setMessage({ type: "", content: "" });
    setValidationErrors([]);
    setAlertErrorMessage("");
    setParentPhoneErrors([]);
    setIsUploadEnabled(false);
    setFile(null);
  };

  const initialValues = {
    class_id: "",
    section_id: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
  });

  const showErrorAlertWithHtml = (title, message) => {
    showErrorAlert(title, message);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage({ type: "error", content: "No file selected for upload." });
      return;
    }
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to upload the data",
      "info",
      "Yes, upload",
      "No, cancel!"
    );
    if (result.isConfirmed) {
      showLoadingAlert();

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await uploadStudent(
          branchId,
          selectedClass,
          selectedSection,
          confirmation,
          formData
        );

        if (response.status === 200) {
          setFileName("No file chosen");
          setMessage({
            type: "confirmation",
            content: "File uploaded successfully.",
          });
          setTableData([]);
          setValidationErrors([]);
          setParentPhoneErrors([]);
          setSelectedClass("");
          setClasses([]);
          setSelectedSection("");
          setIsUploadEnabled(false);
          showSuccessAlert("Uploaded!", "Your data has been uploaded.");
          window.location.reload();
        }
      } catch (error) {
        const errorDetails = error.response.data.message;

        if (Object.keys(errorDetails).length === 1 && errorDetails.phone) {
          const phoneNumbers = errorDetails.phone.join(", ");
          const phoneResult = await showSweetAlert(
            "Are you sure?",
            `Do you want to map these students with the existing phone numbers: ${phoneNumbers}?`,
            "warning",
            "Yes, map them",
            "No, cancel!"
          );
          if (phoneResult.isConfirmed) {
            const formData = new FormData();
            formData.append("file", file);
            try {
              const mappedResponse = await uploadStudent(
                branchId,
                selectedClass,
                selectedSection,
                1,
                formData
              );

              if (mappedResponse.status === 200) {
                setFileName("No file chosen");
                setMessage({
                  type: "confirmation",
                  content: "File uploaded and mapped successfully.",
                });
                setTableData([]);
                setValidationErrors([]);
                setParentPhoneErrors([]);
                setSelectedClass("");
                setSelectedSection("");
                setIsUploadEnabled(false);
                showSuccessAlert(
                  "Mapped!",
                  "Students have been mapped and uploaded."
                );
                window.location.reload();
              }
            } catch (mappingError) {
              console.log("Mapping Error", mappingError.response.data.message);
              const mappingErrorMessage = mappingError.response.data.message;

              const formattedMappingErrorMessage = Object.entries(
                mappingErrorMessage
              )
                .map(
                  ([key, value]) =>
                    `<b>${key.replace(/_/g, " ")}</b>: ${value.join(", ")}`
                )
                .join("<br>");

              setAlertErrorMessage({
                type: "error",
                content: formattedMappingErrorMessage,
              });
              // showErrorAlert("Mapping Error!", formattedMappingErrorMessage);
              showErrorAlertWithHtml(
                "Mapping Error!",
                formattedMappingErrorMessage
              );
            }
          } else if (phoneResult.dismiss === Swal.DismissReason.cancel) {
            showErrorAlert("Cancelled", "Mapping process has been cancelled.");
          }
        } else {
          // Handle other error messages
          const formattedErrorMessage = Object.entries(errorDetails)
            .map(
              ([key, value]) =>
                `<b>${key.replace(/_/g, " ")}</b>: ${value.join(", ")}`
            )
            .join("<br>");
          setAlertErrorMessage({
            type: "error",
            content: formattedErrorMessage,
          });

          showErrorAlertWithHtml(
            "Error! Please recheck your File and upload again",
            formattedErrorMessage
          );
        }
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Your file upload has been cancelled.");
    }
  };

  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Student Create Excel Upload</h3>
                  <div className="card-tools">
                    <button
                      className="btn btn-primary ml-5"
                      onClick={handleDownloadStudentExcel}
                    >
                      <i className="pr-1 nav-icon fa fa-file-excel"></i>
                      Download Sample
                    </button>
                  </div>
                  <br />
                  <hr />
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                  >
                    {({ setFieldValue, values }) => (
                      <Form>
                        <div className="row">
                          {/* Class Dropdown */}
                          <div className="form-group col-4">
                            <label htmlFor="class_id">
                              Class Name <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              id="class_id"
                              name="class_id"
                              className="form-control"
                              value={values.class_id}
                              onChange={(e) => {
                                const selectedClassId = e.target.value;
                                setFieldValue("class_id", selectedClassId);
                                setFieldValue("section_id", "");
                                setSelectedClass(selectedClassId); // Reset section when class changes
                                fetchSections(selectedClassId);
                              }}
                            >
                              <option value="">Select Class</option>
                              {classes.map((cls) => (
                                <option key={cls.id} value={cls.id}>
                                  {cls.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="class_id"
                              component="div"
                              className="text-error"
                            />
                          </div>

                          {/* Section Dropdown */}
                          <div className="form-group col-4">
                            <label htmlFor="section_id">
                              Section Name <span className="mandatory">*</span>
                            </label>
                            <Field
                              as="select"
                              id="section_id"
                              name="section_id"
                              className="form-control"
                              value={values.section_id}
                              onChange={(e) => {
                                const selectedSectionId = e.target.value;
                                setFieldValue("section_id", selectedSectionId);
                                setSelectedSection(selectedSectionId);
                              }}
                              disabled={!values.class_id}
                            >
                              <option value="">Select Section</option>
                              {sections.map((section) => (
                                <option key={section.id} value={section.id}>
                                  {section.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="section_id"
                              component="div"
                              className="text-error"
                            />
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  <hr />
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">File input</label>
                    <div className="input-group col-6">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          accept=".xlsx, .xls"
                          onChange={handleFileChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                        >
                          {fileName}
                        </label>
                      </div>
                      <div
                        className="card-tools"
                        style={{ marginLeft: "10px" }}
                      >
                        <button
                          className="btn btn-secondary"
                          onClick={handleClear}
                        >
                          <i className="pr-1 nav-icon fa fa-ban"></i>
                          Clear
                        </button>
                        <button
                          className="btn btn-primary"
                          style={{ marginLeft: "5px" }}
                          disabled={!isUploadEnabled}
                          onClick={handleUpload}
                        >
                          <i className="pr-1 nav-icon fa fa-upload"></i>
                          Upload
                        </button>
                      </div>
                    </div>
                    {message.content && (
                      <div
                        className={`excel-alert mt-3 col-6 ${
                          message.type === "error"
                            ? "error-alert-danger"
                            : "excel-alert-success"
                        } ${
                          message.type === "fade-out" ? "fade-out" : "fade-in"
                        }`}
                        role="alert"
                      >
                        {message.content}
                      </div>
                    )}
                    {alertErrorMessage.content && (
                      <div
                        className="alert alert-danger mt-3 mb-2"
                        role="alert"
                        style={{
                          backgroundColor: "#f8d7da",
                          borderColor: "#f5c6cb",
                          color: "#721c24",
                        }}
                      >
                        <h5>Please recheck and upload file again!</h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: alertErrorMessage.content,
                          }}
                        ></div>
                      </div>
                    )}

                    <div className="col-12 mt-2 mr-5">
                      {parentPhoneErrors.length > 0 && (
                        <div
                          className="alert alert-light alert-border-danger"
                          role="alert"
                        >
                          <h5>Parent Phone Number: </h5>
                          <ul>
                            {parentPhoneErrors.map((error) => (
                              <li key={error.sharedParentPhone}>
                                {error.sharedParentPhone}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    {validationErrors.length > 0 && (
                      <div className="validation-errors mt-2">
                        <h4>Validation Errors:</h4>
                        <ul>
                          {validationErrors.map((error, index) => (
                            <li key={index} className="row-error">
                              <strong>Row {error.rowIndex}:</strong>
                              <ul className="error-detail">
                                {Object.keys(error)
                                  .filter((key) => key !== "rowIndex")
                                  .map((key, errorIndex) => (
                                    <li key={errorIndex}>{error[key]}</li>
                                  ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <div className="card-body">
                  <DataTable
                    value={tableData.slice(1)}
                    responsiveLayout="scroll"
                    className="p-datatable-sm p-datatable-gridlines custom-datatable"
                  >
                    {tableData[0]?.map((col, index) => (
                      <Column
                        key={index}
                        field={col}
                        header={col}
                        style={{ textAlign: "center" }}
                        body={(rowData) => {
                          const error = validationErrors.find(
                            (err) => err.rowIndex === tableData.indexOf(rowData)
                          );
                          const isEmpty =
                            rowData[index] === undefined ||
                            rowData[index] === "";
                          const hasError =
                            error &&
                            Object.keys(error).some(
                              (key) =>
                                error[key] &&
                                index === tableData[0].indexOf(key)
                            );
                          return (
                            <span
                              className={`cell-content ${
                                isEmpty ? "empty-cell" : ""
                              } ${hasError ? "error-cell" : ""}`}
                            >
                              {isEmpty ? "Empty" : rowData[index]}
                            </span>
                          );
                        }}
                      />
                    ))}
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentUpload;
