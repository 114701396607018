import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import {
  getAllTransportroutes,
  getAllStopsByRouteId,
} from "../../Services/CommonApis/TransportApi";

const StoppagesList = () => {
  const tableRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedroute] = useState([]);
  const { branchId } = useParams();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    fetchAllRoutes(branch_id);
  }, []);
  const branch_id = branchId ? branchId : 1;
  const fetchAllRoutes = async (branch_id) => {
    try {
      const response = await getAllTransportroutes(branch_id);
      const routeOptions = response.data.data;
      setRoutes(routeOptions);
    } catch (error) {
      console.error(error);
    }
  };
  const handleRouteChange = (event, setFieldValue) => {
    const value = event.target.value;
    setFieldValue("route_id", value); // Update Formik field value
    setSelectedroute(value); // Update local state
  };

  useEffect(() => {
    if (selectedRoute) {
      const table = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "pickup_point", title: "Pickup Point" }, // Updated column name
          { data: "pickup_time", title: "Pickup Time" }, // New column for pickup time
          { data: "drop_time", title: "Drop Time" }, // New column for pickup time

          { data: "pickup_distance", title: "Pickup Distance (km)" }, // Updated column name
          { data: "amount", title: "Amount" },

          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                  <a href="/admin/edit-stoppages/${row.id}" title="Edit" class="edit-icon"><i class="fas fa-edit"></i></a>
                  
                  <a href="#" title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
              </div>
          `;
            },
          },
        ],
        lengthChange: true,
        order: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          {
            extend: "copy",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "csv",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excel",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdf",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Locations Data Found",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "desc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getAllStopsByRouteId({
              route_id: selectedRoute,
              _limit: length,
              _page: page,
              _sort: orderColumn,
              _order: orderDirection,
              q: searchValue,
            });

            let jsonData = response.data.data || [];
            let totalCount = response.data.total || 0;

            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData,
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });

      $(tableRef.current).on("click", ".delete-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
      });

      return () => {
        table.destroy();
      };
    }
  }, [selectedRoute]);

  return (
    <>
      <div className="card ml-2 mt-2 mr-2">
        <div className="card-header">
          <h3 className="card-title mt-1">Transport Stoppages List</h3>
        </div>
        <div className="card-body">
          <div className="col-6">
            <Formik initialValues={{ route_id: "" }} onSubmit={() => {}}>
              {({ isSubmitting, setFieldValue }) => (
                <div className="form-group row">
                  <label
                    htmlFor="route_id"
                    className="col-md-3 col-form-label mb-0"
                    style={{ paddingRight: "5px" }} // Reduce the padding between the label and input
                  >
                    Select Route<span className="mandatory">*</span>
                  </label>
                  <div className="col-md-9 pl-0">
                    <Field
                      name="route_id"
                      as="select"
                      className="form-control"
                      style={{ borderColor: "skyblue", marginLeft: "-5px" }} // Reduce left margin to bring input closer
                      onChange={(e) => handleRouteChange(e, setFieldValue)}
                    >
                      <option value="">Select Route</option>
                      {routes.map((route) => (
                        <option key={route.id} value={route.id}>
                          {route.start_point} - {route.end_point}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
              )}
            </Formik>
          </div>
          <table
            ref={tableRef}
            id="example1"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>Pickup Point</th>
                <th>Pickup Time</th>
                <th>Drop Time</th>
                <th>Pickup Distance (km)</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>Pickup Point</th>
                <th>Pickup Time</th>
                <th>Drop Time</th>

                <th>Pickup Distance (km)</th>
                <th>Actions</th>

                <th>Amount</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default StoppagesList;
