import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { getAllJobsDetails } from "../../Services/AdminApis/JobsApi";

const JobDetails = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  useEffect(() => {
    if (tableInstance.current) {
      tableInstance.current.destroy(true);
      tableInstance.current = null;
    }
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "total_jobs" },
        { data: "pending_jobs" },
        { data: "failed_jobs" },
        { data: "failed_job_ids" },
        { data: "options" },
        { data: "cancelled_at" },
        { data: "created_at" },
        { data: "finished_at" },
        {
          data: "status",
          render: (data) => (data === "1" ? "Active" : "Inactive"),
        },
      ],
      lengthChange: true,
      ordering: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "asc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;
          const response = await getAllJobsDetails({
            _limit: length,
            _page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          showErrorAlert(error.response.data.message);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });
  }, []);
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Job Details List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/admin/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Job Details</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Job Details List</h3>
          <div className="card-tools">
          </div>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Total Jobs </th>
                <th>Pending Jobs </th>
                <th>Failed Jobs</th>
                <th>Failed Jobs Ids</th>
                <th>Options</th>
                <th>Cancelled At</th>
                <th>Created At</th>
                <th>Finished At</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Total Jobs </th>
                <th>Pending Jobs </th>
                <th>Failed Jobs</th>
                <th>Failed Jobs Ids</th>
                <th>Options</th>
                <th>Cancelled At</th>
                <th>Created At</th>
                <th>Finished At</th>
                <th>Status</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};
export default JobDetails;
