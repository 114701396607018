import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { useParams } from "react-router-dom";
import { ErrorMessage, Field, Formik } from "formik";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import * as Yup from "yup";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import {
  createAcademicPayments,
  getPaymentMethod,
  getStudentAcademicFee,
} from "../../Services/CommonApis/FeesApi";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { FaMoneyBillWave, FaCcVisa } from "react-icons/fa";

const AcademicFeePayments = () => {
  const tableRef = useRef(null);
  const transportTableRef = useRef(null);
  const { branchId } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedClassId, setClassId] = useState(null);
  const [selectedSectionId, setSectionId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [isClassLoading, setClassLoading] = useState(false);
  const [isSectionLoading, setSectionLoading] = useState(false);
  const [isStudentLoading, setIsStudentLoading] = useState(false);
  const [academicFeeData, setAcademicFeeData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [totalAmount, setTotalAmount] = useState(null);
  const [fineAmount, setFineAmount] = useState(null);
  const [concessionAmount, setConcessionAmount] = useState(null);
  const [payingAmount, setPayingAmount] = useState(null);
  const [selectedRowsAcademic, setSelectedRowsAcademic] = useState([]);
  const [selectedRowsTransport, setSelectedRowsTransport] = useState([]);
  const [paymentType, setPaymentType] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [transportFeeData, setTransportFeeData] = useState([]);

  useEffect(() => {
    fetchClassByBranchId(branchId);
  }, [branchId]);

  useEffect(() => {
    if (selectedClassId) {
      fetchSectionByClassId(selectedClassId);
      setSectionId(null);
      setSectionList([]);
      setSelectedStudentId(null);
      setStudentList([]);
      setAcademicFeeData([]);
      setRefresh((prev) => !prev);
    }
  }, [selectedClassId]);

  useEffect(() => {
    if (selectedSectionId) {
      fetchStudentsByClassSectionId(
        branchId,
        selectedClassId,
        selectedSectionId
      );
      setSelectedStudentId(null);
      setStudentList([]);
      setAcademicFeeData([]);
      setRefresh((prev) => !prev);
    }
  }, [selectedSectionId]);

  useEffect(() => {
    if (selectedStudentId) {
      fetchAcademicSetup(selectedStudentId);
      fetchPaymentMethod(branchId);
    } else {
      setAcademicFeeData([]);
    }
  }, [selectedStudentId]);

  const fetchPaymentMethod = async (branchId) => {
    try {
      const response = await getPaymentMethod({ branch_id: branchId });
      console.log(response.data.data[0]?.id === 2);
      const type = response.data.data[0]?.id;
      setPaymentType(type);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClassByBranchId = async (branchId) => {
    try {
      setClassLoading(true);
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setClassLoading(false);
    }
  };

  const fetchSectionByClassId = async (classId) => {
    try {
      setSectionLoading(true);
      const response = await getSectionByClassId(classId);
      setSectionList(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    } finally {
      setSectionLoading(false);
    }
  };

  const fetchStudentsByClassSectionId = async (
    branchId,
    classId,
    sectionId
  ) => {
    try {
      setIsStudentLoading(true);
      const response = await getstudentbyclassandsection(
        branchId,
        classId,
        sectionId
      );
      setStudentList(response.data.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsStudentLoading(false);
    }
  };

  const fetchAcademicSetup = async (studentId) => {
    try {
      const response = await getStudentAcademicFee({ student_id: studentId });
      const academicData = response.data.data.academic_fees || [];
      const transportData = response.data.data.transport_fees ? [response.data.data.transport_fees] : [];
      setAcademicFeeData(academicData);
      setTransportFeeData(transportData);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Failed to fetch academic setup:", error);
      setAcademicFeeData([]);
      setTransportFeeData([]);
      setRefresh((prev) => !prev);
    }
  };
  
  const updateTotalsFromSelection = (academicRows, transportRows) => {
    let totalAmount = 0;
    let fineAmount = 0;
    let concessionAmount = 0;

    academicRows.forEach((index) => {
      const rowData = $(tableRef.current).DataTable().row(index).data();
      totalAmount += parseFloat(rowData.balance);
      fineAmount += parseFloat(rowData.fine);
      concessionAmount += parseFloat(rowData.discount);
    });

    transportRows.forEach((index) => {
      const rowData = $(transportTableRef.current)
        .DataTable()
        .row(index)
        .data();
      totalAmount += parseFloat(rowData.balance);
      fineAmount += parseFloat(rowData.fine);
      concessionAmount += parseFloat(rowData.discount);
    });

    setTotalAmount(totalAmount);
    setFineAmount(fineAmount);
    setConcessionAmount(concessionAmount);
    setPayingAmount(totalAmount + fineAmount);
  };

  const initializeTable = (tableRef, data, columns) => {
    $(tableRef).DataTable({
      destroy: true,
      data: data,
      columns: columns,
      paging: false,
      searching: false,
      info: false,
      order:true,
      responsive: true,
      dom: "t",
      language: {
        emptyTable: "No data available in table",
      },
    });
  };

  const initializeCheckboxes = (tableRef, tableType) => {
    $(tableRef).on("click", "#selectAll", function () {
      const isChecked = $(this).is(":checked");
      $(tableRef)
        .find("tbody input[type='checkbox']")
        .prop("checked", isChecked);

      const selectedRows = $(tableRef)
        .find("tbody input[type='checkbox']:checked")
        .closest("tr")
        .map(function () {
          return $(tableRef).DataTable().row($(this)).index();
        })
        .get();

      if (tableType === "academic") {
        setSelectedRowsAcademic(selectedRows);
      } else if (tableType === "transport") {
        setSelectedRowsTransport(selectedRows);
      }
    });

    $(tableRef).on("click", "tbody input[type='checkbox']", function () {
      const $checkboxes = $(tableRef).find("tbody input[type='checkbox']");
      const selectedRows = $checkboxes
        .filter(":checked")
        .map(function () {
          return $(this).closest("tr").index();
        })
        .get();

      const currentIndex = $(this).closest("tr").index();

      for (let i = 0; i < currentIndex; i++) {
        if (!$checkboxes.eq(i).is(":checked")) {
          Swal.fire({
            icon: "warning",
            title: "Please make previous payments first.",
            showConfirmButton: true,
          });
          $(this).prop("checked", false);
          return;
        }
      }

      if ($("#selectAll").is(":checked") && !$(this).is(":checked")) {
        $(tableRef).find("input[type='checkbox']").prop("checked", false);
        if (tableType === "academic") {
          setSelectedRowsAcademic([]);
        } else if (tableType === "transport") {
          setSelectedRowsTransport([]);
        }
        return;
      }

      if (tableType === "academic") {
        setSelectedRowsAcademic(selectedRows);
      } else if (tableType === "transport") {
        setSelectedRowsTransport(selectedRows);
      }
    });
  };

  useEffect(() => {
    const columns = [
      {
        data: null,
        title: '<input type="checkbox" id="selectAll" />',
        orderable: false,
        render: function (data, type, row, meta) {
          if (row.paid_status && row.paid_status.toLowerCase() === "paid") {
            return ""; 
          } else {
            return '<input type="checkbox" />';
          }
        },
      },
      { data: "academic_period", title: "Academic Year" },
      { data: "fees_details", title: "Fee Details" },
      { data: "due_date", title: "Last Date" },
      { data: "amount", title: "Fee Amount (₹)" },
      { data: "discount", title: "Concession (₹)" },
      { data: "amount_to_pay", title: "Amount To Pay (₹)" },
      { data: "amount_paid", title: "Amount Paid (₹)" },
      { data: "balance", title: "Balance (₹)" },
      { data: "fine", title: "Fine (₹)" },
      {
        data: "paid_status",
        title: "Status",
        render: function (data, type, row) {
          if (data && data.toLowerCase() === "paid") {
            return '<span style="color: green; font-weight: bold;">Paid</span>';
          } else {
            return '<span style="color: red; font-weight: bold;">Unpaid</span>';
          }
        },
      },
    ];
    

    if (tableRef.current && academicFeeData.length > 0) {
      initializeTable(tableRef.current, academicFeeData, columns);
      initializeCheckboxes(tableRef.current, "academic");
    }
    if (transportTableRef.current && transportFeeData.length > 0) {
      initializeTable(transportTableRef.current, transportFeeData, columns);
      initializeCheckboxes(transportTableRef.current, "transport");
    }
    return () => {
      $(tableRef.current).DataTable().destroy();
      $(transportTableRef.current).DataTable().destroy();
    };
  }, [ academicFeeData,transportFeeData]);

  useEffect(() => {
    updateTotalsFromSelection(selectedRowsAcademic, selectedRowsTransport);
  }, [selectedRowsAcademic, selectedRowsTransport]);


  const handlePayingAmountChange = (e) => {
    const value = e.target.value;
    if (parseFloat(value) > parseFloat(totalAmount)) {
      setErrorMessage(
        "Paying amount should not be greater than the Total Actual Amount."
      );
      e.preventDefault();
    } else {
      setErrorMessage("");
      setPayingAmount(value);
    }
  };

  const handlePaymentMethodSelect = (id) => {
    setPaymentMethod(id);
  };

  const handlePayNow = () => {
    if (paymentType === 2) {
      OfflinePay();
    } else if (paymentType === 1) {
      console.log("Online payment selected");
    } else if (paymentType === 3) {
      if (paymentMethod === 2) {
        OfflinePay();
      } else if (paymentMethod === 1) {
        console.log("Online payment selected");
      } else {
        console.log("No payment method selected");
      }
    } else {
      console.log("Invalid payment type");
    }
  };
  const OfflinePay = async () => {
    const checkboxes = tableRef.current.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    const transportCheckboxes = transportTableRef.current.querySelectorAll(
      'input[type="checkbox"]:checked'
    );

    if (checkboxes.length === 0 && transportCheckboxes.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Please select any option to continue the payment.",
        showConfirmButton: true,
      });
      return;
    }
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the payment",
      "info",
      "Yes, Create",
      "No, cancel!"
    );

    const selectedAcademicData = selectedRowsAcademic.map((index) => {
      const rowData = $(tableRef.current).DataTable().row(index).data();
      return {
        fees_st_pay_id: rowData.id,
        student_id: selectedStudentId,
        fees_amount: rowData.amount,
        discount: rowData.discount,
        amount_to_pay: rowData.amount_to_pay,
        amount_paid: payingAmount,
      };
    });

    const payload =
      selectedAcademicData.length > 0 ? selectedAcademicData[0] : {};

    if (result.isConfirmed) {
      showLoadingAlert();

      try {
        const response = await createAcademicPayments(payload);
        if (response.status === 200) {
          showSuccessAlert("Created!", "The Payment is created successfully.");
          fetchAcademicSetup(selectedStudentId);
        } else {
          const errorMessage =
            response.data?.message ||
            "Payment creation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (error) {
        console.error(error);
        showErrorAlert("Error", error.message);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Payment creation has been cancelled.");
    }
  };

  const initialValues = {
    class_id: "",
    section_id: "",
    student_id: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
    student_id: Yup.string().required("Student is required"),
  });

  const cardStyle = {
    display: "flex",
    alignItems: "center",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    minWidth: "250px",
    backgroundColor: "#ffffff",
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Fee Payments</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Fee Payments</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Fee Payment Table
          </h3>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values }) => (
            <div className="card-body">
              <div className="row">
                <div className="form-group col-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="class_id"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const selectedClassId = e.target.value;
                      setFieldValue("class_id", selectedClassId);
                      setClassId(selectedClassId);
                      setFieldValue("section_id", "");
                      setFieldValue("student_id", "");
                    }}
                  >
                    {isClassLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Class</option>
                        {classList.map((cls) => (
                          <option key={cls.id} value={cls.id}>
                            {cls.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="section_id">
                    Section Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="section_id"
                    name="section_id"
                    className="form-control"
                    value={selectedSectionId || ""}
                    onChange={(e) => {
                      const selectedSectionId = e.target.value;
                      setFieldValue("section_id", selectedSectionId);
                      setSectionId(selectedSectionId);
                      setFieldValue("student_id", "");
                    }}
                    disabled={!selectedClassId}
                  >
                    {isSectionLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Section</option>
                        {sectionList.map((section) => (
                          <option key={section.id} value={section.id}>
                            {section.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="section_id"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="student_id">
                    Student Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="student_id"
                    name="student_id"
                    className="form-control"
                    value={selectedStudentId || ""}
                    onChange={(e) => {
                      const selectedStudentId = e.target.value;
                      setFieldValue("student_id", selectedStudentId);
                      setSelectedStudentId(selectedStudentId);
                    }}
                    disabled={!selectedSectionId}
                  >
                    {isStudentLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Student</option>
                        {studentList.map((student) => (
                          <option key={student.id} value={student.id}>
                            {student.first_name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="student_id"
                    component="div"
                    className="text-error"
                  />
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>

      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Academics Fee</h3>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            id="academicFeeTable"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>
                  <input type="checkbox" id="selectAll" />
                </th>
                <th>Academic Year</th>
                <th>Fee Details</th>
                <th>Last Date</th>
                <th>Fee Amount (₹)</th>
                <th>Concession (₹)</th>
                <th>Amount To Pay (₹)</th>
                <th>Amount Paid (₹)</th>
                <th>Balance (₹)</th>
                <th>Fine (₹)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Transportation Fee</h3>
        </div>
        <div className="card-body">
          <table
            ref={transportTableRef}
            id="transportationFeeTable"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>
                  <input type="checkbox" id="selectAll" />
                </th>
                <th>Academic Year</th>
                <th>Fee Details</th>
                <th>Last Date</th>
                <th>Fee Amount (₹)</th>
                <th>Concession (₹)</th>
                <th>Amount To Pay (₹)</th>
                <th>Amount Paid (₹)</th>
                <th>Balance (₹)</th>
                <th>Fine (₹)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>

      <div
        className="mr-3 mb-3"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <div style={{ display: "flex", gap: "20px" }} className="pr-2">
          {paymentType === 2 && (
            <div style={cardStyle}>
              <input
                type="radio"
                name="paymentMethod"
                defaultChecked={paymentType === 2}
                onChange={() => handlePaymentMethodSelect(2)}
              />
              <div style={{ flexGrow: 1, marginLeft: "10px" }}>
                <div style={{ fontWeight: "bold" }}>Offline Payment</div>
              </div>
              <FaMoneyBillWave size={30} color="#1a1f71" />
            </div>
          )}

          {paymentType === 1 && (
            <div style={cardStyle}>
              <input
                type="radio"
                name="paymentMethod"
                defaultChecked={paymentType === 1}
                onChange={() => handlePaymentMethodSelect(1)}
              />
              <div style={{ flexGrow: 1, marginLeft: "10px" }}>
                <div style={{ fontWeight: "bold" }}>Online Payment</div>
              </div>
              <FaCcVisa size={30} color="#003087" />
            </div>
          )}
        </div>

        {paymentType === 3 && (
          <div style={{ display: "flex", gap: "20px" }} className="pr-2">
            <div style={cardStyle}>
              <input
                type="radio"
                name="paymentMethod"
                checked={paymentMethod === 2}
                onChange={() => handlePaymentMethodSelect(2)}
              />
              <div style={{ flexGrow: 1, marginLeft: "10px" }}>
                <div style={{ fontWeight: "bold" }}>Offline Payment</div>
              </div>
              <FaMoneyBillWave size={30} color="#1a1f71" />
            </div>

            <div style={cardStyle}>
              <input
                type="radio"
                name="paymentMethod"
                checked={paymentMethod === 1}
                onChange={() => handlePaymentMethodSelect(1)}
              />
              <div style={{ flexGrow: 1, marginLeft: "10px" }}>
                <div style={{ fontWeight: "bold" }}>Online Payment</div>
              </div>
              <FaCcVisa size={30} color="#003087" />
            </div>
          </div>
        )}
      </div>

      <div className="d-flex flex-column align-items-end mr-3 pb-3">
        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Total Actual Amount :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-indian-rupee-sign"></i>
                </span>
              </div>
              <input
                type="number"
                className="form-control flex-grow-1"
                value={totalAmount !== null ? totalAmount : ""}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Fine Amount :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-indian-rupee-sign"></i>
                </span>
              </div>
              <input
                type="number"
                className="form-control flex-grow-1"
                value={fineAmount !== null ? fineAmount : ""}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Total Concession Amount :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-indian-rupee-sign"></i>
                </span>
              </div>
              <input
                type="number"
                className="form-control flex-grow-1"
                value={concessionAmount !== null ? concessionAmount : ""}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Concession Percentage :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-percentage"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control flex-grow-1"
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Paying Amount :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-indian-rupee-sign"></i>
                </span>
              </div>
              <input
                type="number"
                className="form-control flex-grow-1"
                value={payingAmount !== null ? payingAmount : ""}
                onChange={handlePayingAmountChange}
                name="amount_paid"
                max={totalAmount}
              />
            </div>
          </div>
          {errorMessage && (
            <div className="text-danger" style={{ marginTop: "5px" }}>
              {errorMessage}
            </div>
          )}
        </div>
        <button className="btn btn-primary mr-2" onClick={handlePayNow}>
          PAY NOW
        </button>
      </div>
    </>
  );
};

export default AcademicFeePayments;
