import React from "react";
import { useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getParentsByNum } from "../../Services/CommonApis/StudentApi";

const StudentDetails = ({ steps, current }) => {
  const { setFieldValue, values } = useFormikContext();

  const [showParentPhone, setShowParentPhone] = useState(false);

  const handleSiblingsChange = (e) => {
    setShowParentPhone(e.target.checked);
  };

  const handleParentSubmit = async () => {
    try {
      const response = await getParentsByNum(values.num);
    } catch (error) {
      console.error("Error fetching parent data: ", error);
    }
  };
  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 2 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Student Details:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 2 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-4">
                <label htmlFor="stfirst_name">
                  First Name <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  name="stfirst_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter First Name"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="stfirst_name"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="middle_name">Middle Name</label>
                <Field
                  name="middle_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter Middle Name"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="middle_name"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="stlast_name">
                  Last Name <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  name="stlast_name"
                  type="text"
                  className="form-control"
                  placeholder="Enter Last Name"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="stlast_name"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="place_of_birth">
                  Place of Birth {" "}
                </label>
                <Field
                  name="place_of_birth"
                  type="text"
                  className="form-control"
                  placeholder="Enter Place of Birth"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="place_of_birth"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="previous_school">
                  Previous School <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  name="previous_school"
                  type="text"
                  className="form-control"
                  placeholder="Enter Previous School"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="previous_school"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="area_of_interest">
                  Area of Interest <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  name="area_of_interest"
                  type="text"
                  className="form-control"
                  placeholder="Enter Area of Interest"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="area_of_interest"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              

             

              

              
              <div className="form-group col-4">
                <label htmlFor="staadhaar_card_no">
                  Aadhaar Number {" "}
                </label>
                <Field
                  name="staadhaar_card_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter Last Study School"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="staadhaar_card_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="stpan_card_no">
                  Pan Number {" "}
                </label>
                <Field
                  name="stpan_card_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter student pan no"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="stpan_card_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              {showParentPhone && (
                <div className="form-group col-4">
                  <label htmlFor="num">
                    Parent Mobile Number <span className="mandatory">* </span>{" "}
                  </label>
                  <Field
                    name="num"
                    type="text"
                    className="form-control"
                    placeholder="Enter Parent Mobile Number"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="num"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
              )}

              {/* {showParentPhone && (
                <div className="row ml-3 mr-3 mt-3">
                  <div className="form-group col-4">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleParentSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default StudentDetails;
