import * as XLSX from "xlsx";

const handleDownload = () => {
  const fileName = "branches.xlsx";
  const headers = [
    "schoolcode", 
    "academicid", 
    "branchname", 
    "branchcode", 
    "affiliationno", 
    "email", 
    "phone", 
    "address", 
    "city", 
    "district", 
    "pincode", 
    "state", 
    "status"
  ];
  const data = [
    [
      "SCH001", 
      1, 
      "Venkatesh Branch", 
      "CB001", 
      "AFF12345", 
      "centralbranche@gmail.com", 
      "9123453789", 
      "123 Main St", 
      "New York", 
      "Manhattan", 
      "100016", 
      "New York State", 
      1
    ],
    [
      "SCH002", 
      2, 
      "Sir Branch", 
      "WB002", 
      "AFF67890", 
      "westside@gmail.com", 
      "9876543210", 
      "456 West Ave", 
      "Los Angeles", 
      "LA County", 
      "900011", 
      "California", 
      1
    ],
  ];

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Branches");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default handleDownload;
