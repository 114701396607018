import React, { useState, useEffect } from "react";
import { getAllAnnouncements } from "../../Services/CommonApis/AnnouncementApi";
import { useParams } from "react-router-dom";
function AnnouncementGrid() {
  const [announcements, setAnnouncements] = useState([]);
  const { branchId } = useParams();

  useEffect(() => {
    fetchAnnouncements(branchId);
  }, [branchId]);

  const fetchAnnouncements = async (branchId) => {
    try {
      const response = await getAllAnnouncements(branchId);
      setAnnouncements(response.data.data);
    } catch (error) {
      console.error("Error fetching announcements:", error);
    }
  };

  const cardStyles = {
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease-in-out",
  };

  const cardHoverStyles = {
    transform: "scale(1.03)",
  };

  const headerStyles = {
    backgroundColor: "#f8f9fa",
    fontSize: "1.25rem",
    fontWeight: "bold",
  };

  const footerStyles = {
    fontSize: "0.9rem",
  };

  return (
    <div className="card-body">
      <div className="d-flex flex-wrap justify-content-between">
        {announcements.map((announcement, index) => (
          <div
            key={announcement.id}
            className="mb-4"
            style={{
              width: "48%",
              minHeight: "300px",
              maxHeight: "380px",
            }}
          >
            <div
              className="card bg-light h-100"
              style={cardStyles}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = cardHoverStyles.transform)
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "none")
              }
            >
              <div
                className="card-header text-center border-bottom"
                style={headerStyles}
              >
                <strong>{announcement.name}</strong>
              </div>
              <div className="card-body">
                <p className="card-text">
                  <b>Application Fee:</b> {announcement.application_fee}
                </p>
                <p className="card-text">
                  <b>Seats Available:</b> {announcement.seats_available}
                </p>
                <p className="card-text">
                  <b>Start Date:</b>{" "}
                  {new Date(announcement.start_date).toLocaleDateString()}
                </p>
                <p className="card-text">
                  <b>End Date:</b>{" "}
                  {new Date(announcement.end_date).toLocaleDateString()}
                </p>
                <p className="card-text">
                  <b>Class Details:</b>
                </p>
                {announcement.class_name.flat().map((detail, index) => {
                  const [className, info] = detail
                    .split(":")
                    .map((x) => x.trim());
                  return (
                    <div key={index} style={{ whiteSpace: "nowrap" }}>
                      <b>{className}:</b> {info}
                    </div>
                  );
                })}
              </div>
              <div className="card-footer text-center text-muted" style={footerStyles}>
                <p className="card-text">
                  <b>Last Submission Date:</b>{" "}
                  {new Date(
                    announcement.last_submission_date
                  ).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default AnnouncementGrid;
