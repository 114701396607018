import React, { useState } from "react";
import AdmissionEnquiry from "./Admissionenquiry";
import Announcement from "../CommonPages/Annoucement";
import SelectionProcess from "./SelectionProcess";
import Admission from "../CommonPages/AdmissionForm";
import Schedule from "../CommonPages/Schedule";
import Enroll from "./Enroll";
function AdmissionManagement() {
  const [activeTab, setActiveTab] = useState("announcements");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header p-2">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "announcements" ? "active" : ""
              }`}
              onClick={() => handleTabClick("announcements")}
            >
              Announcements
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "enquiry" ? "active" : ""}`}
              onClick={() => handleTabClick("enquiry")}
            >
              Form Selling
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "schedule" ? "active" : ""}`}
              onClick={() => handleTabClick("schedule")}
            >
              Schedule
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "selection" ? "active" : ""
              }`}
              onClick={() => handleTabClick("selection")}
            >
              Selection
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "admissionform" ? "active" : ""
              }`}
              onClick={() => handleTabClick("admissionform")}
            >
              Admission Form
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "enroll" ? "active" : ""}`}
              onClick={() => handleTabClick("enroll")}
            >
              Enroll
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${
              activeTab === "announcements" ? "active" : ""
            }`}
            id="announcements"
          >
            {activeTab === "announcements" && <Announcement />}
          </div>
          <div
            className={`tab-pane ${activeTab === "enquiry" ? "active" : ""}`}
            id="enquiry"
          >
            {activeTab === "enquiry" && <AdmissionEnquiry />}
          </div>
          <div
            className={`tab-pane ${
              activeTab === "admissionform" ? "active" : ""
            }`}
            id="admissionform"
          >
            {activeTab === "admissionform" && <Admission />}
          </div>
          <div
            className={`tab-pane ${activeTab === "schedule" ? "active" : ""}`}
            id="schedule"
          >
            {activeTab === "schedule" && <Schedule />}
          </div>

          <div
            className={`tab-pane ${activeTab === "enroll" ? "active" : ""}`}
            id="enroll"
          >
            {activeTab === "enroll" && <Enroll />}
          </div>
          <div
            className={`tab-pane ${activeTab === "selection" ? "active" : ""}`}
            id="selection"
          >
            {activeTab === "selection" && <SelectionProcess />}
          </div>
        </div>
      </div>
    </>
  );
}
export default AdmissionManagement;
