import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  getAllFeesTimelines,
  createFeesTimeline,
  getFeesTimeLinesById,
  updateFeesTimeLinesById,
  deleteFeesTimeLines,
} from "../../Services/CommonApis/FeesApi";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
const FeesTimeLines = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFeeTimelineId, setEditFeeTimelineId] = useState(null);
  const [editFeesTimeLineName, setEditFeesTimeLineName] = useState("");
  const [editFeesTimeLineInstallment, setEditFeesTimeLineInstallment] =
    useState("");

  const handleDeleteTimeLines = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );
    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteFeesTimeLines(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The fees timeline has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert(
          "Error!",
          "There was an error deleting the fees timeline."
        );
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The fees timeline is safe :)");
    }
  };
  const handleCreateFeesTimeLines = async (values) => {
    try {
      showLoadingAlert();
      await createFeesTimeline(values.name, values.installments);
      Swal.close();
      showSuccessAlert("Success!", "The fees timeline has been created.");
      setShowCreateModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert(
        "Error!",
        "There was an error creating the fees timeline."
      );
      console.error(error);
    }
  };
  const handleEditTimeLines = async (id) => {
    try {
      const response = await getFeesTimeLinesById(id);
      const { name, installments } = response.data;
      setEditFeeTimelineId(id);
      setEditFeesTimeLineName(name);
      setEditFeesTimeLineInstallment(installments);
      setShowEditModal(true);
      setEditFeesTimeLineName("");
      setEditFeesTimeLineInstallment("");
    } catch (error) {
      console.error("Error fetching fees timeline details:", error);
      showErrorAlert(
        "Error!",
        "There was an error fetching the fees timeline details."
      );
    }
  };
  const handleUpdateFeesTimeLines = async (values) => {
    try {
      showLoadingAlert();
      await updateFeesTimeLinesById(editFeeTimelineId, {
        name: values.name,
        installments: values.installments,
        status: 1,
      });
      Swal.close();
      showSuccessAlert("Success!", "The fees timeline has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert(
        "Error!",
        "There was an error updating the fees timeline."
      );
      console.error(error);
    }
  };
  useEffect(() => {
    if (tableInstance.current) {
      tableInstance.current.destroy(true);
      tableInstance.current = null;
    }
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "installments" },
        {
          data: "status",
          render: (data) => (data === "1" ? "Active" : "Inactive"),
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "Inactive"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
                ${deleteIcon}
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      order:true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;
          const response = await getAllFeesTimelines({
            _limit: length,
            _page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });
          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          showErrorAlert(error.response.data.message);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDeleteTimeLines(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEditTimeLines(id);
    });
  }, []);
  const initialValues = {
    name: editFeesTimeLineName || "",
    installments: editFeesTimeLineInstallment || "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Fees timeline name is required"),
    
    installments: Yup.number().required("Days is required")
      .typeError("Enter a valid amount.")
      .integer("Enter a valid amount.")
      .required("Amount is required")
      .positive("Enter a valid amount."),
    
  });
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Fees Timelines</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/admin/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Fees Timelines</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Fees Timelines List Table</h3>
          <Button
            variant="primary"
            className="float-right"
            onClick={() => setShowCreateModal(true)}
          >
            Create New
          </Button>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Installments</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Installments</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <Modal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Fees Timeline</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleCreateFeesTimeLines}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="name">Enter Fees Timeline Type</label>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Ex:Monthly, Quarter, Half
                    year, Annual"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="installments">Installments</label>
                  <Field
                    type="number"
                    name="installments"
                    className="form-control"
                    placeholder="Ex: Installments- 1, 3 , 6 ,12 "
                  />
                  <ErrorMessage
                    name="installments"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowCreateModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Fees Timeline</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            name: editFeesTimeLineName,
            installments: editFeesTimeLineInstallment,
          }}
          validationSchema={validationSchema}
          onSubmit={handleUpdateFeesTimeLines}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="name">Fees Timeline Name</label>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Ex:Monthly, Quarter, Half
                    year, Annual"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="days">Installments</label>
                  <Field
                    type="number"
                    name="installments"
                    className="form-control"
                    placeholder="Ex: Installement - 1 , 3 , 6 , 12 "
                  />
                  <ErrorMessage
                    name="installments"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Update Fees Timeline
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export default FeesTimeLines;
