import React, { useEffect, useState } from "react";
import me from "../../Assests/images/user1-128x128.jpg";
import { getSchoolDetailsById, deleteSchoolById } from "../../Services/AdminApis/schoolApi";
import { useParams,useNavigate } from "react-router-dom";
import {  
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import BranchList from "../CommonPages/BranchList";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Formik, Form } from 'formik';
import { dashboardGetStudentCount } from "../../Services/AdminApis/dashboardApi";
import { getDashboardDetails } from "../../Services/AdminApis/dashboardApi";

const SchoolView = () => {
  const navigate=useNavigate()
  const [data, setData] = useState({
    school: {},
    dashboard: {},
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [expanded, setExpanded] = useState('0');
  const { id } = useParams();

   useEffect(() => {
  const fetchData = async () => {
    try {
      const schoolResponse = await getSchoolDetailsById(id);
      const dashboardResponse = await dashboardGetStudentCount();
      const dashboardDetailsResponse = await getDashboardDetails({ school_id: id });
  
      console.log('School Response:', schoolResponse);
      console.log('Dashboard Response:', dashboardResponse);
      console.log('Dashboard Details Response:', dashboardDetailsResponse);
  
      setData({
        school: schoolResponse.school,
        dashboard: dashboardDetailsResponse.data.data,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  fetchData()
   }, [id]);
 
  

  const handleDeactivate = async () => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, deactivate it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await deleteSchoolById(id);
        Swal.close();
        if (response) {
          setIsButtonDisabled(true);
          showSuccessAlert("Deleted!", "The school has been deleted.");
          navigate("/admin/school-list")
        } else {
          showErrorAlert("Error!", "Failed to delete the school.");
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the school.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The school is safe :)");
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // Toggle accordion
  };

  const { school, dashboard } = data;

  return (
    <>
      <section className="content-header" style={{ marginLeft: "316.5px" }}>
        <div className="row">
          {/* Cards for School Stats */}
          <div className="col-lg-4 col-6">
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{dashboard.total_students || 0}</h3>
                <p>Total Students</p>
              </div>
              <div className="icon">
                <i className="ion ion-bag"></i>
              </div>
              <a href="#" className="small-box-footer">
                More info <i className="fas fa-arrow-circle-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{dashboard.total_branches || school.branch_count || 0}</h3>
                <p>Total Branches</p>
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars"></i>
              </div>
              <a href="#" className="small-box-footer">
                More info <i className="fas fa-arrow-circle-right"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-6">
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{dashboard.total_staff || 0}</h3>
                <p>Total Staff</p>
              </div>
              <div className="icon">
                <i className="ion ion-person-add"></i>
              </div>
              <a href="#" className="small-box-footer">
                More info <i className="fas fa-arrow-circle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="content" style={{ marginTop: "-200px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="card card-primary card-outline">
                <div className="card-body box-profile">
                  <div className="text-center">
                    <img
                      className="profile-user-img img-fluid img-circle"
                      src={me}
                      alt="User profile picture"
                    />
                  </div>
                  <h3 className="profile-username text-center">{school.name}</h3>
                  <ul className="list-group list-group-unbordered mb-3">
                    <li className="list-group-item">
                      <b>Branch Count</b> 
                      <span className="float-right">{dashboard.total_branches || school.branch_count || 0}</span>
                    </li>
                    <li className="list-group-item">
                      <b>Address</b>
                      <p> {school.address}, {school.dist}, {school.city}, {school.pin}</p>
                    </li>
                    <li className="list-group-item">
                      <b>School Code</b> <span className="float-right">{school.school_code}</span>
                    </li>
                    <li className="list-group-item">
                      <b>Status</b> 
                      <span className="float-right">
                            {school.status === "1" || school.status === 1 ? "Active" : "Deactive"}
                      </span>
                    </li>
                  </ul>
                  <a
                    href="#"
                    className="btn btn-danger btn-block"
                    onClick={handleDeactivate}
                    disabled={isButtonDisabled}
                  >
                    <b>Deactivate</b>
                  </a>
                </div>
              </div>

              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">About School</h3>
                </div>
                <div className="card-body">
                  <strong>
                    <i className="fas fa-map-marker-alt mr-1"></i> Location
                  </strong>
                  <p className="text-muted">
                    {school.address}, {school.dist}, {school.city}, {school.state}
                  </p>
                  <hr />
                  <strong>Pin</strong>
                  <p className="text-muted">{school.pin}</p>
                </div>
              </div>
            </div>

            <div className="col-md-9" style={{ marginTop: "200px" }}>
              <div className="card">
                <div className="card-header p-2">
                  <ul className="nav nav-pills">
                    <li className="nav-item">
                      <a className="nav-link active" href="#activity" data-toggle="tab">
                        Branches
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#settings" data-toggle="tab">
                        Settings
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="card-body">
                  <div className="tab-content">
                    {/* Branches Tab */}
                    <div className="tab-pane active" id="activity">
                      <div className="accordion" id="branchAccordion">
                        <BranchList />
                      </div>
                    </div>

                    {/* Settings Tab */}
                    <div className="tab-pane" id="settings">
                      <div className="schoolView">
                        <Formik
                          initialValues={{
                            studentGrade: '',
                            feesDueDays: '',
                            reportCardTemplate: '',
                            feeCardTemplate: '',
                            receiptTemplate: '',
                          }}
                          onSubmit={(values) => {
                            console.log('Form submitted with values:', values);
                          }}
                        >
                          {({ values, handleChange, handleSubmit }) => (
                            <Form>
                              {/* Certificate Section */}

                            <Accordion expanded={expanded === '0'} onChange={handleAccordionChange('0')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="certificate-content"
                                id="certificate-header"
                                style={{
                                          backgroundColor: expanded === '0' ? '#d3d3d3' : 'transparent', // Apply background when expanded
                                      }}
                               >
                            <Typography>Certificate</Typography>
                       </AccordionSummary>
                          <AccordionDetails>
                                 <p>Here Certificates for the students are available, but it's under working.....</p>
                        </AccordionDetails>
                           </Accordion>

                           <Accordion expanded={expanded === '1'} onChange={handleAccordionChange('1')}>
                           <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="payment-options-content"
                             id="payment-options-header"
                              style={{
                                         backgroundColor: expanded === '1' ? '#d3d3d3' : 'transparent', // Apply background when expanded
                                 }}
                                >
                             <Typography>Payment Options</Typography>
                             </AccordionSummary>
                             <AccordionDetails>
                             <p>This section will contain the payment options related content.</p>
                             </AccordionDetails>
                             </Accordion>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SchoolView;
