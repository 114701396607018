import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { USERROLEID } from "../../Schemas/userRoles";
import { getBranchById } from "../../Services/CommonApis/branchApi";
import {
  getBranchId,
  getSchoolId,
  getUserRoleId,
} from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import Stoppagesedit from "./Stoppagesedit";

function SchoolSideBar() {
  const [schoolId, setSchoolId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation();
  const adminToken = getSession(COOKIE.ADMIN_TOKEN);
  const [branchData, setBranchData] = useState({});
  const [branchImage, setBranchImage] = useState(null);

  useEffect(() => {
    if (adminToken) {
      setRoleId(getUserRoleId(adminToken));
      if (roleId === USERROLEID.BRANCH_ID) {
        setSchoolId(getSchoolId(adminToken));
        setBranchId(getBranchId(adminToken));
      } else {
        setSchoolId(getSession(COOKIE.SCHOOL_ID));
        setBranchId(getSession(COOKIE.BRANCH_ID));
      }
    }
  }, [adminToken, roleId]);

  useEffect(() => {
    if (branchId) {
      fetchBranchDetails(branchId);
    }
  }, [branchId]);

  const fetchBranchDetails = async (id) => {
    try {
      const response = await getBranchById(id);
      setBranchData(response.data.branch);
      setBranchImage(response.data.branch.branch_meta.logo_file);
    } catch (error) {
      console.error(error);
    }
  };

  const getActiveLinkStyles = (path) => ({
    backgroundColor: location.pathname === path ? "white" : "",
    color: location.pathname === path ? "#808080" : "",
  });

  const links = [
    {
      path: `/school/${schoolId}/branch/${branchId}/dashboard`,
      label: "Dashboard",
      icon: "fas fa-home",
    },
  ];

  const SchoolSettings = [
    {
      path: `/school/${schoolId}/branch/${branchId}/class`,
      label: "Class",
      icon: "fas fa-chalkboard-teacher",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/section`,
      label: "Section",
      icon: "fas fa-columns",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/subject`,
      label: "Subject",
      icon: "fas fa-book",
    },
  ];

  const Certificates = [
    {
      path: `/school/${schoolId}/branch/${branchId}/certificate-list`,
      label: "Certificate List",
      icon: "fa fa-certificate",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/certificate-field-list`,
      label: " Certificate Field List",
      icon: "fas fa-list",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/generate-certificate`,
      label: "Generate Certificate",
      icon: "fas fa-file",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/generate-certificate-list`,
      label: "Student Certificate",
      icon: "fas fa-award",
    },
  ];

  const Users = [
    {
      path: `/school/${schoolId}/branch/${branchId}/staff`,
      label: "Staff",
      icon: "fa-solid fa-user",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/student`,
      label: "Student",
      icon: "fas fa-user-graduate",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/parent`,
      label: "Parent",
      icon: "fas fa-book-open",
    },
  ];

  const academicNotificationsLinks = [
    {
      path: `/school/${schoolId}/branch/${branchId}/attendance`,
      label: "Attendance",
      icon: "fas fa-user-check",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/homework`,
      label: "Homework",
      icon: "fas fa-book-open",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/holiday`,
      label: "Holiday",
      icon: "fas fa-calendar-day",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/shifts`,
      label: "Staff Notifications",
      icon: "fas fa-clock",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/groups`,
      label: "Groups",
      icon: "fa-solid fa-user-group",
    },
  ];
  const Admission=[
  {
      path: `/school/${schoolId}/branch/${branchId}/admission`,
      label: "Announcement",
      icon: "fas fa-book-open",
    },
  ]

  const academicFeesSetup = [
    {
      path: `/school/${schoolId}/branch/${branchId}/fees-dashboard`,
      label: "Fee Dashboard",
      icon: "fa fa-wallet",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/academic-fee-list`,
      label: "Fee Setup",
      icon: "fa fa-wallet",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/fee-payments`,
      label: "Fee Payments",
      icon: "fa fa-money-bill-wave",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/fee-transaction`,
      label: "Fee Transaction",
      icon: "fa fa-receipt",
    },
  ];

  const ReportCardMenu = [
    {
      path: `/school/${schoolId}/branch/${branchId}/reportcard-dashboard`,
      label: "ReportCard Dashboard",
      icon: "fas fa-clipboard-list",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/exam-setup`,
      label: "Exam Setup",
      icon: "fas fa-clipboard-list",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/exam-grade-setup`,
      label: "Exam Grade Setup",
      icon: "fa fa-check-circle",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/exam-report-lock`,
      label: "Report Lock",
      icon: "fa fa-lock",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/remarks`,
      label: "Remark",
      icon: "fas fa-pencil-alt",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/personality-traits`,
      label: "Personality Traits",
      icon: "fas fa-lightbulb",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/report-configuration`,

      label: "Report Card Config",

      icon: "fa fa-wallet",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/marks-entry`,
      label: "Marks Entry",
      icon: "fa fa-keyboard",
    },

    {
      path: `/school/${schoolId}/branch/${branchId}/report`,

      label: "Report Cards",

      icon: "fa fa-check-circle",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/overall`,

      label: "Overall Reports",

      icon: "fa-solid fa-bars-progress",
    },
  ];

  // for transport
  const transportSetup = [
    {
      path: `/school/${schoolId}/branch/${branchId}/route`,
      label: "Route",
      icon: "fas fa-route",
    },

    {
      path: `/school/${schoolId}/branch/${branchId}/transport`,
      label: "Stoppages",
      icon: "fas fa-map-marker-alt",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/transport-vehicles`,
      label: "Transport Vehicle",
      icon: "fas fa-truck",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/transport-pilot`,
      label: "Transport Pilot",
      icon: "fas fa-user-tie",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/transport-details`,
      label: "Transport Details",
      icon: "fas fa-user-tiefas fa-clipboard-list",
    },
  ];

  const libraryPaths = [
    {
      path: `/school/${schoolId}/branch/${branchId}/library`,
      label: "Books",
      icon: "fas fa-book",
    },
  ];

  const handleToggle = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {roleId === USERROLEID.SCHOOL_ID || roleId === USERROLEID.BRANCH_ID ? (
          <Link
            to={`/school/${schoolId}/branch/${branchId}/dashboard`}
            className="brand-link"
          >
            {branchImage ? (
              <img
                src={`${process.env.REACT_APP_IMAGEURL}/${branchImage}`}
                alt="School Logo"
                className="brand-image img-circle elevation-3"
              />
            ) : (
              <img
                src="/plugins/dist/img/profile-dummy-image.jpg"
                alt="Default Logo"
                className="brand-image img-circle elevation-3"
              />
            )}
            <span className="brand-text font-weight-light">
              {branchData.branch_name || "Loading..."}
            </span>
          </Link>
        ) : (
          <Link to="/admin/dashboard" className="brand-link">
            <img
              src="/plugins/dist/img/Logo.png"
              alt="Admin Logo"
              className="brand-image img-circle elevation-3"
            />
            <span className="brand-text font-weight-light">SR EDU KYC</span>
          </Link>
        )}

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {links.map(({ path, label, icon }) => (
                <li className="nav-item" key={path}>
                  <Link
                    to={path}
                    className={`nav-link ${
                      location.pathname === path ? "active-sub" : ""
                    }`}
                    style={getActiveLinkStyles(path)}
                  >
                    <i className={`nav-icon ${icon}`}></i>
                    <p>{label}</p>
                  </Link>
                </li>
              ))}
              <li
                className={`nav-item has-treeview ${
                  openMenu === "SchoolSettings" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "SchoolSettings" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("SchoolSettings")}
                  style={{
                    backgroundColor:
                      openMenu === "SchoolSettings" ? "#007bff" : "",
                    color: openMenu === "SchoolSettings" ? "white" : "",
                  }}
                >
                  <i class="nav-icon fa-solid fa-school-flag"></i>{" "}
                  <p>
                    School Settings
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "SchoolSettings" && (
                  <ul className="nav nav-treeview">
                    {SchoolSettings.map(({ path, label, icon }) => (
                      <li className="nav-item" key={path}>
                        <Link
                          to={path}
                          className={`nav-link ${
                            location.pathname === path ? "active-sub" : ""
                          }`}
                          style={getActiveLinkStyles(path)}
                        >
                          <i className={`nav-icon ${icon}`}></i>
                          <p>{label}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li
                className={`nav-item has-treeview ${
                  openMenu === "Users" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${openMenu === "Users" ? "active" : ""}`}
                  onClick={() => handleToggle("Users")}
                  style={{
                    backgroundColor: openMenu === "Users" ? "#007bff" : "",
                    color: openMenu === "Users" ? "white" : "",
                  }}
                >
                  <i class="nav-icon fa fa-users" aria-hidden="true"></i>
                  <p>
                    Users
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "Users" && (
                  <ul className="nav nav-treeview">
                    {Users.map(({ path, label, icon }) => (
                      <li className="nav-item" key={path}>
                        <Link
                          to={path}
                          className={`nav-link ${
                            location.pathname === path ? "active-sub" : ""
                          }`}
                          style={getActiveLinkStyles(path)}
                        >
                          <i className={`nav-icon ${icon}`}></i>
                          <p>{label}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li
                className={`nav-item has-treeview ${
                  openMenu === "Admission" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${openMenu === "Admission" ? "active" : ""}`}
                  onClick={() => handleToggle("Admission")}
                  style={{
                    backgroundColor: openMenu === "Admission" ? "#007bff" : "",
                    color: openMenu === "Admission" ? "white" : "",
                  }}
                >
                  <i class="nav-icon fas fa-school" aria-hidden="true"></i>
                  <p>
                  Admission
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "Admission" && (
                  <ul className="nav nav-treeview">
                    {Admission.map(({ path, label, icon }) => (
                      <li className="nav-item" key={path}>
                        <Link
                          to={path}
                          className={`nav-link ${
                            location.pathname === path ? "active-sub" : ""
                          }`}
                          style={getActiveLinkStyles(path)}
                        >
                          <i className={`nav-icon ${icon}`}></i>
                          <p>{label}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>

              <li
                className={`nav-item has-treeview ${
                  openMenu === "academicNotifications" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "academicNotifications" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("academicNotifications")}
                >
                  <i className="nav-icon fas fa-bell"></i>
                  <p>
                    Academic Notifications
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "academicNotifications" && (
                  <ul className="nav nav-treeview">
                    {academicNotificationsLinks.map(({ path, label, icon }) => (
                      <li className="nav-item" key={path}>
                        <Link
                          to={path}
                          className={`nav-link ${
                            location.pathname === path ? "active-sub" : ""
                          }`}
                          style={getActiveLinkStyles(path)}
                        >
                          <i className={`nav-icon ${icon}`}></i>
                          <p>{label}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>

              <li
                className={`nav-item has-treeview ${
                  openMenu === "academicFeesSetup" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "academicFeesSetup" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("academicFeesSetup")}
                >
                  <i className="nav-icon fas fa-money-bill"></i>
                  <p>
                    Academic Fees Setup
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "academicFeesSetup" && (
                  <ul className="nav nav-treeview">
                    {academicFeesSetup.map(({ path, label, icon }) => (
                      <li className="nav-item" key={path}>
                        <Link
                          to={path}
                          className={`nav-link ${
                            location.pathname === path ? "active-sub" : ""
                          }`}
                          style={getActiveLinkStyles(path)}
                        >
                          <i className={`nav-icon ${icon}`}></i>
                          <p>{label}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>

              <li
                className={`nav-item has-treeview ${
                  openMenu === "reportCardMenu" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "reportCardMenu" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("reportCardMenu")}
                >
                  <i className="nav-icon fas fa-file-alt"></i>
                  <p>
                    Report Card Config
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "reportCardMenu" && (
                  <ul className="nav nav-treeview">
                    {ReportCardMenu.map(({ path, label, icon }) => (
                      <li className="nav-item" key={path}>
                        <Link
                          to={path}
                          className={`nav-link ${
                            location.pathname === path ? "active-sub" : ""
                          }`}
                          style={getActiveLinkStyles(path)}
                        >
                          <i className={`nav-icon ${icon}`}></i>
                          <p>{label}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>

              <li
                className={`nav-item has-treeview ${
                  openMenu === "Certificates" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "Certificates" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("Certificates")}
                  style={{
                    backgroundColor:
                      openMenu === "Certificates" ? "#007bff" : "",
                    color: openMenu === "Certificates" ? "white" : "",
                  }}
                >
                  <i class="nav-icon fas fa-cog"></i>
                  <p>
                    Certificates
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "Certificates" && (
                  <ul className="nav nav-treeview">
                    {Certificates.map(({ path, label, icon }) => (
                      <li className="nav-item" key={path}>
                        <Link
                          to={path}
                          className={`nav-link ${
                            location.pathname === path ? "active-sub" : ""
                          }`}
                          style={getActiveLinkStyles(path)}
                        >
                          <i className={`nav-icon ${icon}`}></i>
                          <p>{label}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              {/* for transport */}
              <li
                className={`nav-item has-treeview ${
                  openMenu === "transportSetup" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "transportSetup" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("transportSetup")}
                >
                  <i className="nav-icon fa fa-shuttle-van"></i>
                  <p>
                    Transport Setup
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "transportSetup" && (
                  <ul className="nav nav-treeview">
                    {transportSetup.map(({ path, label, icon }) => (
                      <li className="nav-item" key={path}>
                        <Link
                          to={path}
                          className={`nav-link ${
                            location.pathname === path ? "active-sub" : ""
                          }`}
                          style={getActiveLinkStyles(path)}
                        >
                          <i className={`nav-icon ${icon}`}></i>
                          <p>{label}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li
                className={`nav-item has-treeview ${
                  openMenu === "libraryPaths" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "libraryPaths" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("libraryPaths")}
                >
                  <i class="nav-icon fa-solid fa-building-columns"></i>
                  <p>
                    Library
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "libraryPaths" && (
                  <ul className="nav nav-treeview">
                    {libraryPaths.map(({ path, label, icon }) => (
                      <li className="nav-item" key={path}>
                        <Link
                          to={path}
                          className={`nav-link ${
                            location.pathname === path ? "active-sub" : ""
                          }`}
                          style={getActiveLinkStyles(path)}
                        >
                          <i className={`nav-icon ${icon}`}></i>
                          <p>{label}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}

export default SchoolSideBar;
