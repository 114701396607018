import React, { useEffect, useState } from "react";
import { Field, Formik, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getAllExams } from "../../Services/AdminApis/ExamApi";
import { useParams } from "react-router-dom";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import {
  getExamMarks,
  previewPdf,
} from "../../Services/CommonApis/ReportcardApi";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { generatePdf } from "../../Services/CommonApis/ReportcardApi";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";

function Report() {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { schoolId, branchId } = useParams();
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [classId, setClassId] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [exams, setExams] = useState([]);
  const [examMarks, setExamMarks] = useState([]);
  const [isStudentsFetched, setIsStudentsFetched] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  const [selectedHeaderType, setSelectedHeaderType] = useState("1");
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const selectedHeaderTypeRef = useRef(selectedHeaderType);
  const selectedStudentIdsRef = useRef(selectedStudentIds);

  // Update the ref value whenever `selectedStudentIds` changes
  useEffect(() => {
    selectedStudentIdsRef.current = selectedStudentIds;
  }, [selectedStudentIds]);

  useEffect(() => {
    setShowTable(selectedClassId && selectedSection && selectedExamId);
  }, [selectedClassId, selectedExamId, selectedSection]);

  useEffect(() => {
    selectedHeaderTypeRef.current = selectedHeaderType;
  }, [selectedHeaderType]);
  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl); // Free memory when the component is unmounted or URL changes
      }
    };
  }, [pdfUrl]);

  useEffect(() => {
    fetchExams();
    if (branchId) {
      fetchClasses(branchId);
    }
  }, [branchId]);

  const fetchExams = async () => {
    try {
      const response = await getAllExams();
      setExams(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSectionChange = (event, setFieldValue) => {
    const selectedSectionId = event.target.value;
    setSelectedSection(selectedSectionId);
    setFieldValue("section", selectedSectionId);
  };
  const CreateReport = async (data) => {
    try {
      const response = await generatePdf(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadAll = async () => {
    const requestData = {
      branchId,
      selectedHeaderType,
      studentIds: selectedStudentIds,
    };
    if (!selectedStudentIds || selectedStudentIds.length === 0) {
      // Show error alert if no students are selected
      showErrorAlert("Please select at least one student.");
      return; // Prevent further execution if no students are selected
    }
    try {
      const response = generatePdf(
        branchId,
        selectedStudentIdsRef.current,
        selectedHeaderTypeRef.current
      );

      console.log(response);
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const generateAndPreviewPdf = async (branchId,selectedStudentIds,selectedHeaderType) => {
    try {
      const response = await previewPdf(
        branchId,
        selectedStudentIds,
        selectedHeaderType
      );
      // Create a URL for the blob data

      const pdfBlob = response.data;
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Set the pdfUrl state to be used in the modal iframe
      setPdfUrl(pdfUrl);
      setShowModal(true); // Show the modal after setting the URL
    } catch (error) {
      console.error("Error generating PDF", error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showTable) {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              return `<input type="checkbox" class="row-checkbox" data-id="${row.student_id}" />`;
            },
            title:
              '<input type="checkbox" class="mr-2" id="select-all" />Select All',
            width: "20%",
          },
          { data: "id", title: "S.No" },
          { data: "student_name", title: "Student Name" },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              const isLocked = row.is_Lock === 1;
              return `

                <div class="icon-group" style="display: flex; gap: 5px;">
                  <a href="#" title="Print" data-id="${
                    row.student_id
                  }" class="print-icon">
                    <i class="fas fa-print"></i>
                  </a>
                  <a href="#" title="Preview" data-id="${
                    row.student_id
                  }" class="preview-icon">
                    <i class="fas fa-eye"></i>
                  </a>
                  ${
                    isLocked
                      ? `<a href="#">
                      <i class="fas fa-lock" title="Locked"></i>
                      </a>`
                      : `<a href="/school/${schoolId}/branch/${branchId}/view-marks-entry/${row.id}" title="View">
                          <i class="fas fa-edit"></i>
                        </a>`
                  }
                </div>
              `;
            },
          },
        ],
        lengthChange: true,
        order:true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Data Found",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "desc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getExamMarks(
              branchId,
              selectedClassId,
              selectedSection,
              selectedExamId,
            );
            const jsonData = response.data.data || [];
            const totalCount = response.data.total || 0;
            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });

      $("#select-all").on("click", function () {
        const isChecked = $(this).is(":checked");
        $(".row-checkbox").prop("checked", isChecked);

        // Update the selected student IDs based on the select-all checkbox
        const newSelectedIds = isChecked
          ? tableInstance.current.rows().data().pluck("student_id").toArray()
          : [];
        setSelectedStudentIds(newSelectedIds);
      });
      $(tableRef.current).on("click", ".row-checkbox", function () {
        const studentId = $(this).data("id");
        const isChecked = $(this).is(":checked");

        setSelectedStudentIds((prevSelectedIds) =>
          isChecked
            ? [...prevSelectedIds, studentId]
            : prevSelectedIds.filter((id) => id !== studentId)
        );
      });

      $(tableRef.current).on("click", ".print-icon", function (e) {
        e.preventDefault();
        const student_id = $(this).data("id");

        generatePdf(
          branchId,
          selectedStudentIdsRef.current,
          selectedHeaderTypeRef.current
        );
      });

      // Preview Icon Click Handler
      $(tableRef.current).on("click", ".preview-icon", function (e) {
        e.preventDefault();
        const studentId = $(this).data("id");
        generateAndPreviewPdf(
          branchId,
          selectedStudentIdsRef.current,
          selectedHeaderTypeRef.current
        );
      });

      return () => {
        if (tableInstance.current) {
          tableInstance.current.destroy(true);
        }
      };
    }
  }, [branchId, selectedClassId, selectedSection, showTable, selectedExamId]);

  const initialValues = {
    class_id: "",
    section_id: "",
    exam_id: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
    exam_id: Yup.string().required("Exam is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Report Card Generation</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Generate Report Card</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <div className="card">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={CreateReport}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="card-body">
                <div className="row" style={{ flexWrap: "wrap" }}>
                  <div className="form-group col-4">
                    <label htmlFor="class_id">
                      Class Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="class_id"
                      name="class_id"
                      className="form-control"
                      onChange={(e) => {
                        const selectedClassId = e.target.value;
                        setFieldValue("class_id", selectedClassId);
                        setSelectedClassId(selectedClassId);
                        setClassId(selectedClassId);
                        fetchSections(selectedClassId);
                      }}
                    >
                      <option value="">Select Class</option>
                      {classes.map((cls) => (
                        <option key={cls.id} value={cls.id}>
                          {cls.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="class_id"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="col-4">
                    <label>Select Section:</label>
                    <Field
                      as="select"
                      name="section"
                      className="form-control"
                      value={selectedSection}
                      onChange={(e) => handleSectionChange(e, setFieldValue)}
                    >
                      <option value="">Select Section</option>
                      {sections.map((sec) => (
                        <option key={sec.id} value={sec.id}>
                          {sec.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="form-group col-4">
                    <label htmlFor="exam_id">
                      Exam Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="exam_id"
                      name="exam_id"
                      className="form-control"
                      onChange={(e) => {
                        const selectedExamId = e.target.value;
                        setFieldValue("exam_id", selectedExamId);
                        setSelectedExamId(selectedExamId);
                      }}
                    >
                      <option value="">Select Exam</option>
                      {exams.map((exam) => (
                        <option key={exam.id} value={exam.id}>
                          {exam.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="exam_id"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="form-group col-4">
                    <label htmlFor="header_type">
                      Download Type <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="header_type"
                      name="header_type"
                      className="form-control"
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setFieldValue("header_type", selectedValue);
                        setSelectedHeaderType(selectedValue);
                      }}
                    >
                      <option value="">Select Download Type</option>
                      <option value="0">Download with header</option>
                      <option value="1">Download without header</option>
                    </Field>
                    <ErrorMessage
                      name="header_type"
                      component="div"
                      className="text-error"
                    />
                  </div>
                </div>
                {showTable ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table
                            ref={tableRef}
                            className="table table-striped table-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>select</th>
                                <th>S.No</th>
                                <th>Student Name</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                            <tfoot>
                              <tr>
                                <th>Select All</th>

                                <th>S.No</th>
                                <th>Student Name</th>
                                <th>Actions</th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>

                      <div className="text-right mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          style={{
                            position: "absolute",
                            right: "8px",
                            bottom: "15px",
                            marginTop: "7px",
                          }}
                          onClick={handleDownloadAll}
                        >
                          Download All
                        </button>
                      </div>
                    </div>
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      size="lg"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Preview Report Card</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {pdfUrl ? (
                          <object
                            data={pdfUrl}
                            type="application/pdf"
                            width="100%"
                            height="800px"
                          >
                            <p>
                              Sorry, your browser does not support PDFs.{" "}
                              <a href={pdfUrl}>Download the PDF</a>.
                            </p>
                          </object>
                        ) : (
                          <p>Loading PDF...</p>
                        )}
                      </Modal.Body>
                    </Modal>
                  </>
                ) : (
                  <div className="alert alert-info">
                    Please select both class and exam to generate the Report
                    card .
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Report;
