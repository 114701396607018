import React, { useState } from "react";
import TransportDetails from "./TransportDetails";
import TransportDetailsCreate from "./TransportDetailsCreate";

function TransportDetailsManagement() {
  const [activeTab, setActiveTab] = useState("detailslist");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "detailslist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("detailslist")}
            >
              Transport Details List
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createtransport" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createtransport")}
            >
              Create Transport
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${
              activeTab === "detailslist" ? "active" : ""
            }`}
            id="detailslist"
          >
            {activeTab === "detailslist" && <TransportDetails />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createtransport" ? "active" : ""
            }`}
            id="createtransport"
          >
            {activeTab === "createtransport" && <TransportDetailsCreate />}
          </div>
        </div>
      </div>
    </>
  );
}

export default TransportDetailsManagement;
