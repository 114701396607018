import React, { useState } from "react";
import CreateBook from "./CreateBook";
import BooksList from "./BooksList";

function LibraryManagement() {
  const [activeTab, setActiveTab] = useState("bookslist");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "bookslist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("bookslist")}
            >
              Books List
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createbook" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createbook")}
            >
              Create Books
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "uploadbook" ? "active" : ""
              }`}
              onClick={() => handleTabClick("uploadbook")}
            >
              Upload Books
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === "bookslist" ? "active" : ""}`}
            id="bookslist"
          >
            {activeTab === "bookslist" && <BooksList />}
          </div>

          <div
            className={`tab-pane ${activeTab === "uploadbook" ? "active" : ""}`}
            id="uploadbook"
          >
            {activeTab === "uploadbook" && (
              <h1>Currently, the feature is not available. Coming soon.</h1>
            )}
          </div>

          <div
            className={`tab-pane ${activeTab === "createbook" ? "active" : ""}`}
            id="createbook"
          >
            {activeTab === "createbook" && <CreateBook />}
          </div>
        </div>
      </div>
    </>
  );
}

export default LibraryManagement;
