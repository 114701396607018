import React from "react";
import { useState, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import { getAllClasses } from "../../Services/CommonApis/SectionApi";
import { getAllSections } from "../../Services/CommonApis/SectionApi";
import { getmediums } from "../../Services/CommonApis/StudentApi";
import { getGroups } from "../../Services/CommonApis/GroupApi";
import { useParams } from "react-router-dom";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";

const StudentInformation = ({ steps, current }) => {
  const { setFieldValue, values } = useFormikContext();
  const [academics, setAcademics] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);

  const [classes, setClasses] = useState([]);
  const [classOptions, setClassOptions] = useState([]);

  const [section, setSection] = useState([]);
  const [medium, setmedium] = useState([]);
  const [branch, setbranch] = useState([]);
  const { branchId } = useParams();
  useEffect(() => {
    Academics();
    getmedium();
  }, []);

  const [groups, setGroups] = useState([]);
  useEffect(() => {
    if (branchId) {
      fetchGroups(branchId);
    }
  }, [branchId]);

  const fetchGroups = async (branchId) => {
    try {
      const response = await getGroups(branchId);
      setGroups(response.data.data);
    } catch (error) {
      console.error("Error fetching schools:", error);
    }
  };

  const Academics = async () => {
    const response = await getAllAcademics();
    setAcademics(response.data.data);
  };

  useEffect(() => {
    fetchClasses(branchId);
  }, [branchId]);
  const fetchClasses = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const getmedium = async () => {
    const response = await getmediums();
    setmedium(response.data.data);
  };
  const handleclasssection = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      const sections = response.data.sections.map((section) => ({
        value: section.id,
        label: section.name,
      }));
      setSectionOptions(sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 1 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Student Information:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 1 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-4">
                <label htmlFor="academic_year_id">
                  Academic Year <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  name="academic_year_id"
                  as="select"
                  className="form-control"
                  style={{ borderColor: "skyblue" }}
                >
                  <option value="">Select Year</option>
                  {academics.map((year) => {
                    const startDate = new Date(year.start_date);
                    const endDate = new Date(year.end_date);

                    const startMonthYear = startDate.toLocaleString("default", {
                      month: "short",
                      year: "numeric",
                    });
                    const endMonthYear = endDate.toLocaleString("default", {
                      month: "short",
                      year: "numeric",
                    });

                    const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                    return (
                      <option key={year.id} value={year.id}>
                        {formattedDateRange}
                      </option>
                    );
                  })}
                </Field>
                <ErrorMessage
                  name="academic_year_id"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="roll_no">
                  Roll No  {" "}
                </label>
                <Field
                  name="roll_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter Roll No"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="roll_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="medium_id">
                  Medium <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  name="medium_id"
                  className="form-control"
                  style={{ borderColor: "skyblue" }}
                >
                  <option value="">Select Medium</option>
                  {medium.map((medium) => (
                    <option key={medium.id} value={medium.id}>
                      {medium.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="medium_id"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="class_id">
                  Class <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  name="class_id"
                  className="form-control"
                  onChange={(e) => {
                    const classId = e.target.value;
                    handleclasssection(classId);
                    setFieldValue("class_id", classId);
                  }}
                >
                  <option value="">Select Class</option>
                  {classOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="class_id"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="section_id">
                  Section <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  name="section_id"
                  className="form-control"
                  style={{ borderColor: "skyblue" }}
                  value={values.section_id}
                  onChange={(e) => setFieldValue("section_id", e.target.value)}
                >
                  <option value="">Select Section</option>
                  {sectionOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="section_id"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="group_id">
                  Group{" "}
                </label>
                <Field
                  as="select"
                  name="group_id"
                  className="form-control"
                  style={{ borderColor: "skyblue" }}
                >
                  <option value="">Select Group</option>

                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="group_id"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="reg_no">Reg No</label>
                <Field
                  name="reg_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter Reg No"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="reg_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="emis_no">EMIS No</label>
                <Field
                  name="emis_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter EMIS No"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="emis_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="cse_no">CSE No</label>
                <Field
                  name="cse_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter CSE No"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="cse_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="file_no">
                  File No {" "}
                </label>
                <Field
                  name="file_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter File No"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="file_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="admission_no">
                  Admission No <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  name="admission_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter Admission No"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="admission_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="admission_date">
                  Admission Date <span className="mandatory">* </span>
                </label>
                <Field
                  name="admission_date"
                  type="date"
                  className="form-control"
                />
                <ErrorMessage
                  name="admission_date"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="application_no">
                  Application No {" "}
                </label>
                <Field
                  name="application_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter Application No"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="application_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="joining_quota">
                  Joining Quota {" "}
                </label>
                <Field
                  as="select"
                  name="joining_quota"
                  className="form-control"
                  style={{ borderColor: "skyblue" }}
                >
                  <option value="">Select Quota</option>
                  <option value="GENERAL">General</option>
                  <option value="2">SC/ST</option>
                  <option value="4">OBC</option>
                  <option value="1">Minority</option>
                </Field>
                <ErrorMessage
                  name="joining_quota"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="fee_book_no">Fee Book No </label>
                <Field
                  name="fee_book_no"
                  type="text"
                  className="form-control"
                  placeholder="Enter Fee Book No"
                />
                <ErrorMessage
                  name="fee_book_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default StudentInformation;
