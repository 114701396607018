import React, { useState } from "react";
import ClassList from "../CommonPages/ClassList";
import CreateClass from "../CommonPages/CreateClass";
import ClassUpload from "./ClassUpload";

function ClassManagement() {
  const [activeTab, setActiveTab] = useState("classlist");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "classlist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("classlist")}
            >
              Class List
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createclass" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createclass")}
            >
              Create Class
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "uploadclass" ? "active" : ""
              }`}
              onClick={() => handleTabClick("uploadclass")}
            >
              Upload Class
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === "classlist" ? "active" : ""}`}
            id="classlist"
          >
            {activeTab === "classlist" && <ClassList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "uploadclass" ? "active" : ""
            }`}
            id="uploadclass"
          >
            {activeTab === "uploadclass" && <ClassUpload />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createclass" ? "active" : ""
            }`}
            id="createclass"
          >
            {activeTab === "createclass" && <CreateClass />}
          </div>
        </div>
      </div>
    </>
  );
}

export default ClassManagement;
