import Api from "../Api";
// apis for fees types
export const createExamGrade = (data) => {
  return Api.post("/create-exam-grade", data);
};
export const getAllExamGrades = (params) => {
  return Api.get("/get-exam-grades", { params });
};
export const getExamGradeById = async (id) => {
  const response = await Api.get(`/get-exam-grade/${id}`);
  return response.data;
};
export const updateExamGradeById = async (id, data) => {
  const response = await Api.post(`/update-exam-grade/${id}`, data);
  return response.data;
};
export const deleteExamGrade = (id) => {
  return Api.delete(`/delete-exam-grade/${id}`);
};
