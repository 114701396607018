import * as XLSX from "xlsx";

const handleDownloadStudentExcel = () => {
  const fileName = "students.xlsx";
  const headers = [
    "Academic Year",
    "Roll No",
    "Student First Name",
    "Student Last Name",
    "Date of Birth",
    "Email",
    "Admission No",
    "Admission Date",
    "Application No",
    "Gender",
    "Blood Group",
    "Student Aadhaar Card No ",
    "Student Pan Card No",
    "Parent First Name",
    "Last Name",
    "Phone",
    "Parent gender",
    "Parent Aadhaar Card No",
    "Parent Pan card no",
    "Permanent Address",
  ];

  const data = [
    [
      "2024", // Academic Year
      "001", // Roll No
      "Aarav", // First Name
      "Sharma", // Last Name
      "15-05-2010", // Date of Birth
      "aarav.sharma@example.com", // Email
      "AD12345", // Admission No
      "15-05-2020", // Admission Date
      "APP56789", // Application No
      "Male", // Gender
      "O+", // Blood Group
      "123456789012", // Student Aadhaar Card No
      "DFHPM2771H", // Student Pan Card No
      "Rajesh", // Parent First Name
      "Sharma", // Parent Last Name
      "9876543210", // Parent Phone No
      "Male", // Parent gender
      "234567890123", // Parent Aadhaar Card No
      "DFHPM2771S", // Parent Pan Card No
      "123, Marine Drive, Mumbai, Maharashtra, 400001, India", // Permanent Address
    ],
    [
      "2024", // Academic Year
      "002", // Roll No
      "Anaya", // First Name
      "Verma", // Last Name
      "15-05-2000", // Date of Birth
      "anaya.verma@example.com", // Email
      "AD67890", // Admission No
      "15-07-2021", // Admission Date
      "APP11223", // Application No
      "Female", // Gender
      "A+", // Blood Group
      "567891012345", // Student Aadhaar Card No
      "DFHPM2771J", // Student Pan Card No
      "Rohit", // Parent First Name
      "Verma", // Parent Last Name
      "8765432109", // Parent Phone No
      "Male", // Parent gender
      "678901234567", // Parent Aadhaar Card No
      "DFHPM2771V", // Parent Pan Card No
      "45, Connaught Place, Delhi, 110001, India", // Permanent Address
    ],
    [
      "2024", // Academic Year
      "003", // Roll No
      "Ishaan", // First Name
      "Patel", // Last Name
      "15-08-2020", // Date of Birth
      "ishaan.patel@example.com", // Email
      "AD98765", // Admission No
      "19-05-2020", // Admission Date
      "APP33456", // Application No
      "Male", // Gender
      "B+", // Blood Group
      "789012345678", // Student Aadhaar Card No
      "DFHPM2771M", // Student Pan Card No
      "Suresh", // Parent First Name
      "Patel", // Parent Last Name
      "9876504321", // Parent Phone No
      "Male", // Parent gender
      "123498765432", // Parent Aadhaar Card No
      "DFHPM2771B", // Parent Pan Card No
      "23, SG Highway, Ahmedabad, Gujarat, 380015, India", // Permanent Address
    ],
    // Add more rows as needed
  ];

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
    if (worksheet[cellAddress]) {
      worksheet[cellAddress].s = {
        font: { bold: true },
      };
    }
  }

  worksheet["!cols"] = headers.map(() => ({ width: 20 })); // Adjust column width if needed

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Schools");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
    cellStyles: true,
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default handleDownloadStudentExcel;
