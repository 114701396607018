import React, { useState } from "react";
import StoppagesList from "./StoppagesList";
import Transport from "../CommonPages/CreateStoppages";

function TransportManagement() {
  const [activeTab, setActiveTab] = useState("stoppagelist");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "stoppagelist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("stoppagelist")}
            >
              Stoppages List
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createstoppage" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createstoppage")}
            >
              Create Stoppage
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${
              activeTab === "stoppagelist" ? "active" : ""
            }`}
            id="stoppagelist"
          >
            {activeTab === "stoppagelist" && <StoppagesList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createstoppage" ? "active" : ""
            }`}
            id="createstoppage"
          >
            {activeTab === "createstoppage" && <Transport />}
          </div>
        </div>
      </div>
    </>
  );
}

export default TransportManagement;
