import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { getExamConfigs } from "../../Services/CommonApis/ReportcardApi";
import { deleteexamconfig, editexamconfig } from "../../Services/CommonApis/ReportcardApi";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getAllExams } from "../../Services/AdminApis/ExamApi";
import { useParams } from "react-router-dom";
import EditExamConfig from "../AdminPages/EditExamConfig";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";

const ViewExamConfig = () => {
  const { branchId } = useParams();
  const tableRef = useRef(null);
  const tableInstance = useRef(null);

  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [selectedExamId, setSelectedExamId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");

  const [ExamConfigEdit, setExamConfigEdit] = useState(false);
  const [ExamConfigId, setExamConfigId] = useState(null);
  const [examConfigData, setExamConfigData] = useState(null);



  useEffect(() => {
    if (branchId) {
      fetchClasses(branchId);
    }
    fetchExams();
  }, [branchId]);

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);

    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response?.data?.sections || []);
    } catch (error) {
      console.error("Error fetching sections:", error);


    }
  };

  const fetchExams = async () => {
    try {
      const response = await getAllExams();
      setExams(response.data.data);
    } catch (error) {
      console.error("Error fetching exams:", error);

    }
  };


  const handleEdit = async (id) => {
    setExamConfigId(id);
    setExamConfigEdit(true);

    const response = await editexamconfig(id);
    const examConfigData = response.data.data;
    setExamConfigData(examConfigData);
  };



  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await deleteexamconfig(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The Exam Config has been deleted.");
        setRefresh((prev) => !prev);
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The staff member is safe :)");
    }
  };



  useEffect(() => {

    const table = $(tableRef.current).DataTable({
      destroy: true,
      processing: false,
      serverSide: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      responsive: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      lengthChange: true,
      order:true,
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Staff Data Found",
      },
      ajax: async (data, callback) => {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex = data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection = data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const exam_id = selectedExamId || "";
          const class_id = selectedClassId || "";
          const section_id = selectedSectionId || "";

          const response = await getExamConfigs(exam_id, class_id, section_id, {
            _limit: length,
            _page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {

          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
      columns: [
        { data: "id", title: "ID", width: "20px" },
        { data: "subject_name", title: "Subj", width: "80px" },
        { data: "internal", title: "Int", width: "40px" },
        { data: "external", title: "Ext", width: "40px" },
        { data: "max_marks", title: "Max", width: "40px" },
        { data: "pass_marks", title: "Pass", width: "40px" },
        {
          data: "is_grade",
          title: "Grade",
          width: "20px",
          render: (data) => (data ? "Yes" : "No"),
        },
        {
          data: "is_average",
          title: "Avg",
          width: "20px",
          render: (data) => (data ? "Yes" : "No"),
        },
        {
          data: "add_in_grand",
          title: "Grand",
          width: "40px",
          render: (data) => (data ? "Yes" : "No"),
        },
        {
          data: "topper_visible",
          title: "Topper",
          width: "30px",
          render: (data) => (data ? "Yes" : "No"),
        },
        {
          data: "rank_visible",
          title: "Rank",
          width: "30px",
          render: (data) => (data ? "Yes" : "No"),
        },
        // { data: "sequence", title: "Seq", width: "30px" },
        { data: "lock_report", title: "Lock", width: "40px" },
        {
          data: null,
          title: "Actions",
          width: "50px",
          render: (data, type, row) => `
            <div class="icon-group" style="display: flex; gap: 5px;">
              <a href="#" title="Edit" data-id="${row.id}" class="edit-icon">
                <i class="fas fa-edit"></i>
              </a>
              <a href="#" title="Delete" data-id="${row.id}" class="delete-icon">
                <i class="fas fa-trash"></i>
              </a>
            </div>
          `,
        },
      ],

    });
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });
    return () => {
      table.destroy();
    };
  }, [selectedClassId, selectedSectionId, selectedExamId]);

  const handleBack = (classId, sectionId, examIdd) => {
    setSelectedClassId(null);
    setSelectedSectionId(null);
    setSelectedExamId(null);
    setExamConfigEdit(false);

  };

  return (
    <>
      {ExamConfigEdit ? (
        <EditExamConfig ExamConfigId={ExamConfigId} examConfigData={examConfigData} onBack={handleBack} />
      ) : (
        <>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <h1>View Exam Configuration</h1>
                </div>
              </div>
            </div>
          </section>

          <div className="card ml-3 mr-3">
            <div className="card-header">
              <h1 className="card-title">View Exam Config</h1>
            </div>
            <div className="card-body">
              <div
                className="form-row mb-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="form-group" style={{ flex: "1 1 30%", marginRight: "10px" }}>
                  <label htmlFor="classSelect">Class</label>
                  <select
                    id="classSelect"
                    className="form-control"
                    value={selectedClassId}
                    onChange={(e) => {
                      setSelectedClassId(e.target.value);
                      fetchSections(e.target.value);
                    }}
                  >
                    <option value="">Select Class</option>
                    {classes.map((cls) => (
                      <option key={cls.id} value={cls.id}>
                        {cls.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group" style={{ flex: "1 1 30%", marginRight: "10px" }}>
                  <label htmlFor="sectionSelect">Section</label>
                  <select
                    id="sectionSelect"
                    className="form-control"
                    value={selectedSectionId}
                    onChange={(e) => setSelectedSectionId(e.target.value)}
                  >
                    <option value="">Select Section</option>
                    {sections.map((section) => (
                      <option key={section.id} value={section.id}>
                        {section.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group" style={{ flex: "1 1 30%" }}>
                  <label htmlFor="examSelect">Exam</label>
                  <select
                    id="examSelect"
                    className="form-control"
                    value={selectedExamId}
                    onChange={(e) => setSelectedExamId(e.target.value)}
                  >
                    <option value="">Select Exam</option>
                    {exams.map((exam) => (
                      <option key={exam.id} value={exam.id}>
                        {exam.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="table-responsive">
                <table ref={tableRef} className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Subj</th>
                      <th>Int</th>
                      <th>Ext</th>
                      <th>Max</th>
                      <th>Pass</th>
                      <th>Grade</th>
                      <th>Avg</th>
                      <th>Grand</th>
                      <th>Topper</th>
                      <th>Rank</th>
                      {/* <th>Seq</th> */}
                      <th>Lock</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

};

export default ViewExamConfig;
