import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../Styles/SchoolUpload.css";
import handleSectionDownload from "../../Utility/AdminUtility/SectionExcel";
import { uploadSection } from "../../Services/CommonApis/SectionApi";
import {
  showSweetAlert,
  showLoadingAlert,
  showSuccessAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { SectionExcelValidation } from "../../Utility/AdminUtility/AdminValidation";

const SectionUpload = () => {
  const { branchId } = useParams();
  const [fileName, setFileName] = useState("No file chosen");
  const [file, setFile] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [message, setMessage] = useState({ type: "", content: "" });
  const [validationErrors, setValidationErrors] = useState([]);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);

  useEffect(() => {
    if (message.content) {
      const duration = 3000;
      const fadeDuration = 500;

      const showTimer = setTimeout(() => {
        setMessage((prev) => ({
          ...prev,
          type: "fade-out",
        }));
      }, duration);

      const clearTimer = setTimeout(() => {
        setMessage({ type: "", content: "" });
      }, duration + fadeDuration);

      return () => {
        clearTimeout(showTimer);
        clearTimeout(clearTimer);
      };
    }
  }, [message]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileName(selectedFile ? selectedFile.name : "No file chosen");
    setMessage({ type: "", content: "" });
    handleShowData(selectedFile);
    setFile(selectedFile);
  };

  const handleShowData = (selectedFile) => {
    if (!selectedFile) {
      setMessage({ type: "error", content: "No file selected" });
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const errors = SectionExcelValidation(jsonData);

      if (errors.length === 0) {
        setTableData(jsonData);
        setMessage({
          type: "confirmation",
          content: "Please upload data by clicking upload button",
        });
        setValidationErrors([]);
        setIsUploadEnabled(true);
      } else {
        setMessage({
          type: "error",
          content: "Data validation failed. Please check the file.",
        });
        setValidationErrors(errors);
        setTableData(jsonData);
        setIsUploadEnabled(false);
      }
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  const handleClear = () => {
    setFileName("No file chosen");
    setTableData([]);
    setMessage({ type: "", content: "" });
    setValidationErrors([]);
    setIsUploadEnabled(false);
    setFile(null);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage({ type: "error", content: "No file selected for upload." });
      return;
    }

    const result = await showSweetAlert(
      "Are you sure?",
      "You want to upload the data",
      "info",
      "Yes, upload",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await uploadSection(formData); // Call API with formData

        if (response.status === 200) {
          setFileName("No file chosen");
          setMessage({
            type: "confirmation",
            content: "File uploaded successfully.",
          });
          setTableData([]);
          setValidationErrors([]);
          setIsUploadEnabled(false);
          showSuccessAlert("Uploaded!", "Your data has been uploaded.");
        } else {
          const errorMessage =
            response.data?.message || "File upload failed. Please try again.";
          setMessage({ type: "error", content: errorMessage });
          showErrorAlert("Error!", errorMessage);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during file upload.";
        setMessage({ type: "error", content: errorMessage });
        showErrorAlert("Error!", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Your file upload has been cancelled.");
    }
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Section Upload</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">SectionExcelUpload</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Section Create Excel Upload</h3>
                  <div className="card-tools">
                    <button
                      className="btn btn-primary ml-5"
                      onClick={handleSectionDownload}
                    >
                      <i className="pr-1 nav-icon fa fa-file-excel"></i>
                      Download Sample
                    </button>
                  </div>
                  <br />
                  <hr />
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">File input</label>
                    <div className="input-group col-6">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          accept=".xlsx, .xls"
                          onChange={handleFileChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                        >
                          {fileName}
                        </label>
                      </div>
                      <div
                        className="card-tools"
                        style={{ marginLeft: "10px" }}
                      >
                        <button
                          className="btn btn-secondary"
                          onClick={handleClear}
                        >
                          <i className="pr-1 nav-icon fa fa-ban"></i>
                          Clear
                        </button>
                        <button
                          className="btn btn-primary"
                          style={{ marginLeft: "5px" }}
                          disabled={!isUploadEnabled}
                          onClick={handleUpload}
                        >
                          <i className="pr-1 nav-icon fa fa-upload"></i>
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <DataTable
                    value={tableData.slice(1)}
                    responsiveLayout="scroll"
                    className="p-datatable-sm p-datatable-gridlines custom-datatable"
                  >
                    {tableData[0]?.map((col, index) => (
                      <Column
                        key={index}
                        field={col}
                        header={col}
                        style={{ textAlign: "center" }}
                        body={(rowData) => {
                          const error = validationErrors.find(
                            (err) => err.rowIndex === tableData.indexOf(rowData)
                          );
                          const isEmpty =
                            rowData[index] === undefined ||
                            rowData[index] === "";
                          const hasError =
                            error &&
                            Object.keys(error).some(
                              (key) =>
                                error[key] &&
                                index === tableData[0].indexOf(key)
                            );
                          return (
                            <span
                              className={`cell-content ${
                                isEmpty ? "empty-cell" : ""
                              } ${hasError ? "error-cell" : ""}`}
                            >
                              {isEmpty ? "Empty" : rowData[index]}
                            </span>
                          );
                        }}
                      />
                    ))}
                  </DataTable>
                  {message.content && (
                    <div
                      className={`excel-alert mt-3 col-6 ${
                        message.type === "error"
                          ? "error-alert-danger"
                          : "excel-alert-success"
                      } ${
                        message.type === "fade-out" ? "fade-out" : "fade-in"
                      }`}
                      role="alert"
                    >
                      {message.content}
                    </div>
                  )}
                  {validationErrors.length > 0 && (
                    <div className="validation-errors mt-2">
                      <h4>Validation Errors:</h4>
                      <ul>
                        {validationErrors.map((error, index) => (
                          <li key={index} className="row-error">
                            <strong>Row {error.rowIndex}:</strong>
                            <ul className="error-detail">
                              {Object.keys(error)
                                .filter((key) => key !== "rowIndex")
                                .map((key, errorIndex) => (
                                  <li key={errorIndex}>{error[key]}</li>
                                ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionUpload;
