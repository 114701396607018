import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { getStudentAcademicFee } from "../../Services/CommonApis/FeesApi";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { getParentId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";

const ParentFeePayment = () => {
  const tableRef = useRef(null);
  const transportTableRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [totalAmount, setTotalAmount] = useState(null);
  const [fineAmount, setFineAmount] = useState(null);
  const [concessionAmount, setConcessionAmount] = useState(null);
  const [payingAmount, setPayingAmount] = useState(null);
  const [selectedRowsAcademic, setSelectedRowsAcademic] = useState([]);
  const [selectedRowsTransport, setSelectedRowsTransport] = useState([]);
  const [academicFeeData, setAcademicFeeData] = useState([]);
  const [transportFeeData, setTransportFeeData] = useState([]);
  const [parentId, setParentId] = useState(null);

  useEffect(() => {
    const adminToken = getSession(COOKIE.ADMIN_TOKEN);

    if (adminToken) {
      setParentId(getParentId(adminToken));
    }
  }, []);

  useEffect(() => {
    if (parentId) {
      fetchAcademicSetup(parentId);
    }
  }, [parentId]);

  const fetchAcademicSetup = async (parentId) => {
    try {
      const response = await getStudentAcademicFee({ parent_id: parentId });
      const { academic_fees, transport_fees } = response.data.data;
      setAcademicFeeData(academic_fees || []);
      setTransportFeeData(transport_fees ? [transport_fees] : []);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Failed to fetch academic setup:", error);
    }
  };

  const updateTotalsFromSelection = (academicRows, transportRows) => {
    let totalAmount = 0;
    let fineAmount = 0;
    let concessionAmount = 0;

    academicRows.forEach((index) => {
      const rowData = $(tableRef.current).DataTable().row(index).data();
      totalAmount += parseFloat(rowData.amount_to_pay);
      fineAmount += parseFloat(rowData.fine);
      concessionAmount += parseFloat(rowData.discount);
    });

    transportRows.forEach((index) => {
      const rowData = $(transportTableRef.current)
        .DataTable()
        .row(index)
        .data();
      totalAmount += parseFloat(rowData.amount_to_pay);
      fineAmount += parseFloat(rowData.fine);
      concessionAmount += parseFloat(rowData.discount);
    });

    setTotalAmount(totalAmount);
    setFineAmount(fineAmount);
    setConcessionAmount(concessionAmount);
    setPayingAmount(totalAmount + fineAmount);
  };

  const initializeTable = (tableRef, data, columns) => {
    $(tableRef).DataTable({
      destroy: true,
      data: data,
      columns: columns,
      paging: false,
      searching: false,
      info: false,
      order:true,
      responsive: true,
      dom: "t",
      language: {
        emptyTable: "No data available in table",
      },
    });
  };

  const initializeCheckboxes = (tableRef, tableType) => {
    $(tableRef).on("click", "#selectAll", function () {
      const isChecked = $(this).is(":checked");
      $(tableRef)
        .find("tbody input[type='checkbox']")
        .prop("checked", isChecked);

      const selectedRows = $(tableRef)
        .find("tbody input[type='checkbox']:checked")
        .closest("tr")
        .map(function () {
          return $(tableRef).DataTable().row($(this)).index();
        })
        .get();

      if (tableType === "academic") {
        setSelectedRowsAcademic(selectedRows);
      } else if (tableType === "transport") {
        setSelectedRowsTransport(selectedRows);
      }
    });

    $(tableRef).on("click", "tbody input[type='checkbox']", function () {
      const selectedRows = $(tableRef)
        .find("tbody input[type='checkbox']:checked")
        .closest("tr")
        .map(function () {
          return $(tableRef).DataTable().row($(this)).index();
        })
        .get();

      if (tableType === "academic") {
        setSelectedRowsAcademic(selectedRows);
      } else if (tableType === "transport") {
        setSelectedRowsTransport(selectedRows);
      }
    });
  };

  useEffect(() => {
    const columns = [
      {
        data: null,
        title: '<input type="checkbox" id="selectAll" />',
        orderable: false,
        render: function (data, type, row, meta) {
          // Only show checkbox if the status is 'unpaid'
          if (row.paid_status && row.paid_status.toLowerCase() === "paid") {
            return ""; // Do not show checkbox for paid rows
          } else {
            return '<input type="checkbox" />'; // Show checkbox for unpaid rows
          }
        },
      },
      { data: "academic_period", title: "Academic Year" },
      { data: "fees_details", title: "Fee Details" },
      { data: "due_date", title: "Last Date" },
      { data: "amount", title: "Fee Amount (₹)" },
      { data: "discount", title: "Concession (₹)" },
      { data: "amount_to_pay", title: "Amount To Pay (₹)" },
      { data: "amount_paid", title: "Amount Paid (₹)" },
      { data: "balance", title: "Balance (₹)" },
      { data: "fine", title: "Fine (₹)" },
      {
        data: "paid_status",
        title: "Status",
        render: function (data, type, row) {
          if (data && data.toLowerCase() === "paid") {
            return '<span style="color: green; font-weight: bold;">Paid</span>';
          } else if (data && data.toLowerCase() === "unpaid") {
            return '<span style="color: red; font-weight: bold;">Unpaid</span>';
          } else {
            return '<span style="color: gray; font-weight: bold;">Unknown</span>';
          }
        },
      },
    ];

    if (academicFeeData.length > 0) {
      initializeTable(tableRef.current, academicFeeData, columns);
      initializeCheckboxes(tableRef.current, "academic");
    } else {
      initializeTable(tableRef.current, [], columns);
    }

    initializeTable(transportTableRef.current, transportFeeData, columns);
    initializeCheckboxes(transportTableRef.current, "transport");

    return () => {
      $(tableRef.current).DataTable().destroy();
      $(transportTableRef.current).DataTable().destroy();
    };
  }, [refresh, academicFeeData, transportFeeData]);

  useEffect(() => {
    updateTotalsFromSelection(selectedRowsAcademic, selectedRowsTransport);
  }, [selectedRowsAcademic, selectedRowsTransport]);

  const handlePayNow = () => {
    console.log("Paying Amount: ₹", payingAmount);
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Academics Fee</h3>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            id="academicFeeTable"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>
                  <input type="checkbox" id="selectAll" />
                </th>
                <th>Academic Year</th>
                <th>Fee Details</th>
                <th>Last Date</th>
                <th>Fee Amount (₹)</th>
                <th>Concession (₹)</th>
                <th>Amount To Pay (₹)</th>
                <th>Amount Paid (₹)</th>
                <th>Balance (₹)</th>
                <th>Fine (₹)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Transportation Fee</h3>
        </div>
        <div className="card-body">
          <table
            ref={transportTableRef}
            id="transportationFeeTable"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>
                  <input type="checkbox" id="selectAll" />
                </th>
                <th>Academic Year</th>
                <th>Fee Details</th>
                <th>Last Date</th>
                <th>Fee Amount (₹)</th>
                <th>Concession (₹)</th>
                <th>Amount To Pay (₹)</th>
                <th>Amount Paid (₹)</th>
                <th>Balance (₹)</th>
                <th>Fine (₹)</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
      <div className="d-flex flex-column align-items-end">
        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Total Actual Amount :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-indian-rupee-sign"></i>
                </span>
              </div>
              <input
                type="number"
                className="form-control flex-grow-1"
                value={totalAmount !== null ? totalAmount : ""}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Fine Amount :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-indian-rupee-sign"></i>
                </span>
              </div>
              <input
                type="number"
                className="form-control flex-grow-1"
                value={fineAmount !== null ? fineAmount : ""}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Total Concession Amount :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-indian-rupee-sign"></i>
                </span>
              </div>
              <input
                type="number"
                className="form-control flex-grow-1"
                value={concessionAmount !== null ? concessionAmount : ""}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Concession Percentage :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-percentage"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control flex-grow-1"
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group d-flex">
            <label className="mb-0 flex-shrink-0" style={{ width: "40%" }}>
              Paying Amount :
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-indian-rupee-sign"></i>
                </span>
              </div>
              <input
                type="number"
                className="form-control flex-grow-1"
                value={payingAmount !== null ? payingAmount : ""}
                onChange={(e) => setPayingAmount(e.target.value)}
              />
            </div>
          </div>
        </div>
        <button className="btn btn-primary" onClick={handlePayNow}>
          PAY NOW
        </button>
      </div>
    </>
  );
};

export default ParentFeePayment;
