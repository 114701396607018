import Api from "../Api";

export const createTransportRoute = async (data) => {
  try {
    const response = await Api.post("/create-transport-route", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllTransportRoute = async (params) => {
  try {
    const response = await Api.get("/get-transport-routes", { params });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDropDownRoute = async (branchId) => {
  return await Api.get("/get-routes", {
    params: { branch_id: branchId },
  });
};

export const deleteTransportRouteById = async (id) => {
  try {
    const response = await Api.delete(`/delete-transport-route/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTransportRouteById = async (id, data) => {
  try {
    const response = await Api.post(`/update-transport-route/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTransportRouteById = async (id) => {
  try {
    const response = await Api.get(`/get-transport-route/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getTransportrouteById = (selectedRoute) => {
  const id = selectedRoute;
  return Api.get(`/get-transport-route/${id}`);
};
export const getAllTransportroutes = (branch_id) => {
  return Api.get(`/get-transport-routes`, {
    params: {
      branch_id: branch_id,
    },
  });
};
export const createStops = (data) => {
  return Api.post(`/create-transport-route-stop`, data);
};

export const getAllStopsByRouteId = (params) => {
  return Api.get(`/get-transport-route-stops`, {
    params
  });
};

export const getstoproutesbyid = (id) => {
  return Api.get(`/get-transport-route-stop/${id}`);
};

// for trasnport details Api
export const createTransportDetails = async (data) => {
  try {
    const response = await Api.post("/create-transport-details", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllTransportDetails = async (params) => {
  return await Api.get("/get-transport-details", { params });
};

export const deleteTransportDetailsById = async (id) => {
  try {
    const response = await Api.delete(`/delete-transport-details/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTransportDetailsById = async (id, data) => {
  return await Api.post(`/update-transport-details/${id}`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getTransportDetailsById = async (id) => {
  try {
    const response = await Api.get(`/get-transport-details/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllStop = async (routeId) => {
  return await Api.get("/get-transport-route-stops", {
    params: {
      route_id: routeId,
    },
  });
};

export const updatestop = (id, data) => {
  return Api.post(`update-transport-route-stop/${id}`, data);
};

// for parents portal
export const getAllTransportList = async (studentId) => {
  try {
    const response = await Api.get("/get-student-transport-details", {
      params: {
        student_id: studentId,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching transport list:", error);
    throw error;
  }
};
