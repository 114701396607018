import * as XLSX from "xlsx";

const handleClassDownload = () => {
  const fileName = "classess.xlsx";
  const headers = ["Class name"];
  const data = [
    ["LKG"],
    ["UKG"],
    ["Class I"],
    ["Class II"],
    ["Class III"],
    ["Class IV"],
  ];

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Schools");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default handleClassDownload;
