import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    let title = "";

    if (path.startsWith("/admin")) {
      if (path === "/admin/admin-dashboard") {
        title = "Admin Dashboard";
      } else if (path === "/admin/individual-dashboard") {
        title = "Individual Dashboard";
      } else if (path === "/admin/school-list") {
        title = "School List";
      } else if (path.startsWith("/admin/school-edit")) {
        title = "School Edit";
      } else if (path === "/admin/school-create") {
        title = "Create School";
      } else if (path === "/admin/school-upload") {
        title = "Upload School";
      } else if (path.startsWith("/admin/school-view")) {
        title = "View School";
      } else if (
        path.startsWith("/admin/school/") &&
        path.includes("/branch-list")
      ) {
        title = "Branch List";
      } else if (
        path.startsWith("/admin/school/") &&
        path.includes("/branch-edit")
      ) {
        title = "Edit Branch";
      } else if (path === "/admin/academic-create") {
        title = "Create Academic Details";
      } else if (path.startsWith("/admin/academic-edit")) {
        title = "Edit Academic Details";
      } else if (path === "/admin/academic-list") {
        title = "Academic List";
      } else if (path === "/admin/language-list") {
        title = "Language List";
      } else if (path === "/admin/language-create") {
        title = "Create Language";
      } else if (path.startsWith("/admin/language-edit")) {
        title = "Edit Language";
      } else if (path === "/admin/department-create") {
        title = "Create Department";
      } else if (path === "/admin/department-list") {
        title = "Department List";
      } else if (path.startsWith("/admin/department-edit")) {
        title = "Edit Department";
      } else if (path.startsWith("/admin/qualification-edit")) {
        title = "Edit Qualification";
      } else if (path === "/admin/qualification-list") {
        title = "Qualification List";
      } else if (path.startsWith("/admin/attendance")) {
        title = "Attendance";
      } else if (path.startsWith("/admin/subject-list")) {
        title = "Subject List";
      } else if (path.startsWith("/admin/fees-dashboard")) {
        title = "Fees Dashboard";
      } else if (path.startsWith("/admin/fees-list")) {
        title = "Fees List";
      } else if (path.startsWith("/admin/fees-timelines")) {
        title = "Fees Timelines";
      } else if (path.startsWith("/admin/fees-paytypes")) {
        title = "Fees Pay-Types";
      } else if (path.startsWith("/admin/fees-discount")) {
        title = "Fees Discount";
      } else if (path.startsWith("/admin/reportcard-dashboard")) {
        title = "Report Card Dashboard";
      } else if (path.startsWith("/admin/exam-setup")) {
        title = "Exam Setup";
      } else if (path.startsWith("/admin/exam-grade-setup")) {
        title = "Exam Grade Setup";
      } else if (path.startsWith("/admin/exam-report-lock")) {
        title = "Exam report Lock";
      } else if (path.startsWith("/admin/id-card")) {
        title = "Id Card Template List";
      } else if (path.startsWith("/admin/certificate-list")) {
        title = "Certificate List";
      } else if (path.startsWith("/admin/certificate-field-list")) {
        title = "Certificate Field List";
      } else if (path.startsWith("/admin/library")) {
        title = "Library List";
      } else if (path.startsWith("/admin/job-details")) {
        title = "Job Details";
      } else if (path.startsWith("/admin/job-notification-details")) {
        title = "Job Notification Details";
      }
    }
    // School Routes
    else if (path.startsWith("/school")) {
      if (path === "/school") {
        title = "School Dashboard";
      } else if (path.startsWith("/school") && path.includes("branch")) {
        if (path.includes("/student")) {
          title = "Student Management";
        } else if (path.includes("/class")) {
          title = "Class Management";
        } else if (path.includes("/staff")) {
          title = "Staff Management";
        } else if (path.includes("/subject")) {
          title = "Subject Management";
        } else if (path.includes("/section")) {
          title = "Section Management";
        } else if (path.includes("/attendance")) {
          title = "Attendance Management";
        } else if (path.includes("/homework")) {
          title = "Homework Management";
        } else if (path.includes("/parent")) {
          title = "Parent Management";
        } else {
          title = "School Dashboard";
        }
      }
    } else {
      title = "Dashboard";
    }

    document.title = title;
  }, [location]);

  return null;
};

export default PageTitle;
