import Api from "../Api"

export const dashboardGetStudentCount = async()=>{
    const payload = {
        school_id:1,
        branch_id:1
        
    }
    return await Api.get(`/get-dashboard-details/`,{payload},)
}


export const getDashboardDetails = async (params) => {
  const response = await Api.get(`/get-dashboard-details`, { params });
  return response;
};

