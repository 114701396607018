import React, { useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { useFormikContext } from "formik";

const PersonalInfo = ({ current, steps }) => {
  const { values, handleChange, setFieldValue, touched, errors } =
    useFormikContext();

  useEffect(() => {
  }, [values]);

  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 3 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Personal Info:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 2 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-4">
                <label htmlFor="date_of_birth">
                  Date of Birth <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="date"
                  className="form-control"
                  id="date_of_birth"
                  name="date_of_birth"
                  placeholder="Enter Student Grade"
                  max={new Date().toISOString().split("T")[0]}
                  style={{ borderColor: getBorderColor("date_of_birth") }}
                />
                <ErrorMessage
                  name="date_of_birth"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="gender">
                  Gender <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="gender"
                  name="gender"
                  placeholder="Select Gender"
                  style={{
                    borderColor: getBorderColor("gender"),
                    borderRadius: "0px",
                  }}
                >
                  <option label="Select gender" disabled />
                  <option value="female" label="Female" />
                  <option value="male" label="Male" />
                </Field>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="blood_group">
                  Blood Group <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="blood_group"
                  name="blood_group"
                  placeholder="Select Blood Group"
                  style={{
                    borderColor: getBorderColor("blood_group"),
                    borderRadius: "0px",
                  }}
                >
                  <option label="Select blood group" disabled />
                  <option value="A+" label="A+" />
                  <option value="B+" label="B+" />
                  <option value="A-" label="A-" />
                  <option value="B-" label="B-" />
                  <option value="O-" label="O-" />
                  <option value="O+" label="O+" />
                  <option value="O+" label="AB+" />
                  <option value="O+" label="AB-" />
                </Field>
                <ErrorMessage
                  name="blood_group"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-4">
                <label htmlFor="religion">
                  Religion <span className="optional">(optional)</span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="religion"
                  name="religion"
                  placeholder="Enter Religion"
                  style={{
                    borderColor: getBorderColor("religion"),
                  }}
                />
                <ErrorMessage
                  name="religion"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="cast">
                  Caste <span className="optional">(optional)</span>
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="cast"
                  name="cast"
                  placeholder="Enter Caste"
                  style={{
                    borderColor: "skyblue",
                  }}
                />
                <ErrorMessage
                  name="cast"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="nationality">
                  Nationality <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="nationality"
                  name="nationality"
                  placeholder="Ex: Indian"
                  style={{
                    borderColor: getBorderColor("nationality"),
                  }}
                />
                <ErrorMessage
                  name="nationality"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-4">
                <label htmlFor="mother_tounge">
                  Mother Tongue <span className="optional">(optional)</span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="mother_tounge"
                  name="mother_tounge"
                  placeholder="Ex: Telugu"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="mother_tounge"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="anniversary_date">Anniversary Date</label>
                <Field
                  type="date"
                  className="form-control"
                  id="anniversary_date"
                  name="anniversary_date"
                  placeholder="Enter anniversary date"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="anniversary_date"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="spouse_name">
                  Spouse Name <span className="optional">(optional)</span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="spouse_name"
                  name="spouse_name"
                  placeholder="Enter spouse name"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="spouse_name"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-4">
                <label htmlFor="assigned_activity">
                  Assigned Activity <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="assigned_activity"
                  name="assigned_activity"
                  placeholder="Enter assigned activity"
                  style={{ borderColor: getBorderColor("assigned_activity") }}
                />
                <ErrorMessage
                  name="assigned_activity"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4 d-flex flex-column ">
                <label className="mb-2">
                  Marital Status<span className="mandatory">* </span>
                </label>
                <div className="d-flex align-items-center mt-2">
                  <div className="form-check mr-3">
                    <Field
                      type="radio"
                      className="form-check-input"
                      id="single"
                      name="marital_status"
                      value="single"
                    />
                    <label className="form-check-label" htmlFor="single">
                      Single
                    </label>
                  </div>
                  <div className="form-check mr-3 ">
                    <Field
                      type="radio"
                      className="form-check-input "
                      id="married"
                      name="marital_status"
                      value="married"
                    />
                    <label className="form-check-label" htmlFor="married">
                      Married
                    </label>
                  </div>
                  <div className="form-check mr-3">
                    <Field
                      type="radio"
                      className="form-check-input"
                      id="widowed"
                      name="marital_status"
                      value="widowed"
                    />
                    <label className="form-check-label" htmlFor="widowed">
                      Widowed
                    </label>
                  </div>
                  <div className="form-check mr-3">
                    <Field
                      type="radio"
                      className="form-check-input"
                      id="divorced"
                      name="marital_status"
                      value="divorced"
                    />
                    <label className="form-check-label" htmlFor="divorced">
                      Divorced
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  name="marital_status"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4 d-flex ml-0 mt-4">
                <Field
                  type="checkbox"
                  className="form-control mt-3 ml-0"
                  id="kid_studying"
                  name="kid_studying"
                  style={{ width: "31px", height: "25px" }}
                  checked={values.kid_studying === "yes"}
                  onChange={(e) => {
                    setFieldValue(
                      "kid_studying",
                      e.target.checked ? "yes" : "no"
                    );
                  }}
                />
                <label className="mt-3 ml-1" htmlFor="kid_studying">
                  Is kid studying?
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default PersonalInfo;
