import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function FeedbackComponent() {
  const [feedbackList, setFeedbackList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const tableRef = useRef(null);
  const tableInstance = useRef(null);

  // Handle form submission to add new feedback
  const submitFeedback = (values) => {
    const newFeedback = {
      id: feedbackList.length + 1,
      date: new Date().toISOString().split("T")[0],
      parentName: values.parentName,
      feedback: values.feedback,
      status: "Pending",
    };
    setFeedbackList([...feedbackList, newFeedback]);
    setShowModal(false);
    if (tableInstance.current) {
      tableInstance.current.ajax.reload();
    }
  };

  useEffect(() => {
    // Initialize DataTable
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      data: feedbackList,
      columns: [
        { data: "id", title: "ID" },
        { data: "date", title: "Date" },
        { data: "parentName", title: "Parent Name" },
        { data: "feedback", title: "Feedback" },
        {
          data: "status",
          title: "Status",
          render: (data) => (data === "Pending" ? "Pending" : "Reviewed"),
        },
      ],
      lengthChange: true,
      order:true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: false,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, [feedbackList]);

  const initialValues = {
    parentName: "",
    feedback: "",
  };

  const validationSchema = Yup.object({
    parentName: Yup.string().required("Parent name is required"),
    feedback: Yup.string().required("Feedback is required"),
  });

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Parent Feedback</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-header">
                    <h3 style={{ paddingTop: "5px" }} className="card-title">
                      Feedback List
                    </h3>
                    <button
                      className="btn btn-primary float-right"
                      onClick={() => setShowModal(true)}
                    >
                      Add Feedback
                    </button>
                  </div>
                  <table
                    ref={tableRef}
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add Feedback Modal */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Feedback</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitFeedback}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="parentName">Parent Name</label>
                    <Field
                      type="text"
                      name="parentName"
                      className="form-control"
                      placeholder="Enter parent name"
                    />
                    <ErrorMessage
                      name="parentName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="feedback">Feedback/Complaint/Concern</label>
                    <Field
                      as="textarea"
                      name="feedback"
                      className="form-control"
                      placeholder="Enter feedback"
                    />
                    <ErrorMessage
                      name="feedback"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Submit Feedback
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </section>
    </div>
  );
}
