import * as Yup from "yup";

export const SchoolValidation = {
  tab1: Yup.object({
    name: Yup.string().required("School Name is required"),
    school_code: Yup.string().required("School Code is required"),
    city: Yup.string().required("City is required"),
    dist: Yup.string().required("District is required"),
    state: Yup.string().required("State is required"),
    pin: Yup.string()
      .length(6, "Pin must be exactly 6 digits")
      .required("Pin is required"),
    address: Yup.string().required("Address is required"),
  }),

  tab2: Yup.object({
    branch_name: Yup.string().required("Branch Name is required"),
    branch_code: Yup.string().required("Branch Code is required"),
    branch_email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid Gmail address"
      )
      .required("Email is required"),
    branch_phone: Yup.string()
      .length(10, "Mobile No must be exactly 10 digits")
      .required("Mobile No is required"),
  }),

  tab3: Yup.object({
    branch_city: Yup.string().required("City is required"),
    branch_dist: Yup.string().required("District is required"),
    branch_state: Yup.string().required("State is required"),
    branch_pin: Yup.string()
      .length(6, "Pin must be exactly 6 digits")
      .required("Pin is required"),
    branch_address: Yup.string().required("Address is required"),
  }),
  tab4: Yup.object({
    print_file: Yup.mixed().required("Print file is required"),
    report_card: Yup.mixed().required("Report card is required"),
    logo_file: Yup.mixed().required("Logo file is required"),
    text_logo: Yup.mixed().required("Text logo is required"),
  }),
};

// SchoolUpload Excel Validation for SchoolUpload.js Component
export const SchoolExcelValidation = (data) => {
  const errors = [];
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^\d{10}$/;

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const rowErrors = {};

    if (!row || row.length < 16) {
      rowErrors.missingFields = "Missing required fields.";
    } else {
      const email = row[10];
      if (email && !emailPattern.test(email)) {
        rowErrors.email = "Invalid email format.";
      }

      const pin = row[6];
      if (!pin || !/^\d{6}$/.test(pin)) {
        rowErrors.pin = "Pin must be a 6-digit number.";
      }

      const phone = row[11];
      if (phone && !phonePattern.test(phone)) {
        rowErrors.phone = "Mobile No must be exactly 10 digits.";
      }
    }

    if (Object.keys(rowErrors).length > 0) {
      errors.push({ rowIndex: i, ...rowErrors });
    }
  }

  return errors;
};

export const ClassExcelValidation = (data) => {
  const errors = [];
  const classNames = new Set();

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const rowErrors = {};

    // Validate if class name exists in the row
    const className = row[0]; // Assuming the class name is in the first column
    if (!className || className.trim() === "") {
      rowErrors.className = "Class name is required.";
    } else {
      // Normalize the class name by removing all spaces and converting to lowercase
      const normalizedClassName = className.replace(/\s+/g, "").toLowerCase();
      if (classNames.has(normalizedClassName)) {
        rowErrors.className = "Duplicate class name found.";
      } else {
        classNames.add(normalizedClassName);
      }
    }

    if (Object.keys(rowErrors).length > 0) {
      errors.push({ rowIndex: i, ...rowErrors });
    }
  }

  return errors;
};



export const AcademicValidationSchema = Yup.object({
  academic_years: Yup.string().required("Academic Year is required"),
  start_date: Yup.date().required("Start Date is required"),
  end_date: Yup.date()
    .required("End Date is required")
    .test("is-greater", "End Date must be after Start Date", function (value) {
      const { start_date } = this.parent;
      return value && start_date ? value > start_date : true;
    }),
  academic_description: Yup.string().required("Description is required"),
});

export const LanguageValidationschema = Yup.object({
  name: Yup.string().required("Name is required"),
});

export const DepartmentValidationschema = Yup.object({
  name: Yup.string().required("Name is required"),
});

export const SubjectValidationschema = Yup.object({
  name: Yup.string().required("Name is required"),
});

export const QualificationValidationschema = Yup.object({
  name: Yup.string().required("Name is required"),
});

export const SectionExcelValidation = (data) => {
  const errors = [];
  const allowedPattern = /^[a-zA-Z0-9-_]+$/; // Only alphabets, numbers, '-', and '_'

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const rowErrors = {};

    // Validate class name
    const className = row[0]; // Assuming the class name is in the second column
    if (!className || className.trim() === "") {
      rowErrors.className = "Class name is required.";
    } else if (!allowedPattern.test(className.trim())) {
      rowErrors.className = "Class name contains invalid characters.";
    }

    // Validate section name
    const sectionName = row[1]; // Assuming the section name is in the first column
    if (!sectionName || sectionName.trim() === "") {
      rowErrors.sectionName = "Section name is required.";
    } else if (!allowedPattern.test(sectionName.trim())) {
      rowErrors.sectionName = "Section name contains invalid characters.";
    }

    // Collect errors for the row
    if (Object.keys(rowErrors).length > 0) {
      errors.push({ rowIndex: i, ...rowErrors });
    }
  }

  return errors;
};

export const StudentExcelValidation = (data) => {
  const headers = [
    "Academic Year",
    "Roll No",
    "Student First Name",
    "Student Last Name",
    "Date of Birth",
    "Email",
    "Admission No",
    "Admission Date",
    "Application No",
    "Gender",
    "Blood Group",
    "Student Aadhaar Card No",
    "Student Pan Card No",
    "Parent First Name",
    "Parent Last Name",
    "Parent Phone No",
    "Parent Gender",
    "Parent Aadhaar Card No",
    "Parent Pan Card No",
    "Permanent Address",
  ];

  const errors = [];
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const aadhaarPattern = /^\d{12}$/;
  const panPattern = /^[A-Z]{5}\d{4}[A-Z]{1}$/;
  const phonePattern = /^\d{10}$/;

  const uniqueFields = {
    rollNo: new Set(),
    email: new Set(),
    admissionNo: new Set(),
    applicationNo: new Set(),
    studentAadhaar: new Set(),
    studentPan: new Set(),
    parentAadhaar: new Set(),
    parentPan: new Set(),
  };

  const parentPhoneMapping = {}; // Track phone numbers and associated student names

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const rowErrors = {};

    if (!row || row.length < headers.length) {
      rowErrors.missingFields = "Missing required fields.";
    } else {
      const [
        ,
        // Skip Academic Year
        rollNo,
        studentFirstName,
        studentLastName,
        ,
        email,
        admissionNo,
        ,
        applicationNo,
        ,
        ,
        studentAadhaar,
        studentPan,
        parentFirstName,
        parentLastName,
        parentPhone,
        ,
        parentAadhaar,
        parentPan,
      ] = row;

      const studentFullName = `${studentFirstName} ${studentLastName}`.trim();

      // Check for duplicate Roll No
      if (rollNo) {
        if (uniqueFields.rollNo.has(rollNo)) {
          rowErrors.rollNo = "Duplicate Roll No.";
        } else {
          uniqueFields.rollNo.add(rollNo);
        }
      }

      // Check for valid email and duplicates
      if (email) {
        if (!emailPattern.test(email)) {
          rowErrors.email = "Invalid email format.";
        } else if (uniqueFields.email.has(email)) {
          rowErrors.email = "Duplicate Email.";
        } else {
          uniqueFields.email.add(email);
        }
      }

      // Check for duplicate Admission No
      if (admissionNo) {
        if (uniqueFields.admissionNo.has(admissionNo)) {
          rowErrors.admissionNo = "Duplicate Admission No.";
        } else {
          uniqueFields.admissionNo.add(admissionNo);
        }
      }

      // Check for duplicate Application No
      if (applicationNo) {
        if (uniqueFields.applicationNo.has(applicationNo)) {
          rowErrors.applicationNo = "Duplicate Application No.";
        } else {
          uniqueFields.applicationNo.add(applicationNo);
        }
      }

      // Check for valid Aadhaar and duplicates
      if (studentAadhaar) {
        if (!aadhaarPattern.test(studentAadhaar)) {
          rowErrors.studentAadhaar = "Invalid Student Aadhaar No.";
        } else if (uniqueFields.studentAadhaar.has(studentAadhaar)) {
          rowErrors.studentAadhaar = "Duplicate Student Aadhaar No.";
        } else {
          uniqueFields.studentAadhaar.add(studentAadhaar);
        }
      }

      if (parentAadhaar) {
        if (!aadhaarPattern.test(parentAadhaar)) {
          rowErrors.parentAadhaar = "Invalid Parent Aadhaar No.";
        } else if (uniqueFields.parentAadhaar.has(parentAadhaar)) {
          rowErrors.parentAadhaar = "Duplicate Parent Aadhaar No.";
        } else {
          uniqueFields.parentAadhaar.add(parentAadhaar);
        }
      }

      // Check for valid PAN and duplicates
      if (studentPan) {
        if (!panPattern.test(studentPan)) {
          rowErrors.studentPan = "Invalid Student PAN No.";
        } else if (uniqueFields.studentPan.has(studentPan)) {
          rowErrors.studentPan = "Duplicate Student PAN No.";
        } else {
          uniqueFields.studentPan.add(studentPan);
        }
      }

      if (parentPan) {
        if (!panPattern.test(parentPan)) {
          rowErrors.parentPan = "Invalid Parent PAN No.";
        } else if (uniqueFields.parentPan.has(parentPan)) {
          rowErrors.parentPan = "Duplicate Parent PAN No.";
        } else {
          uniqueFields.parentPan.add(parentPan);
        }
      }

      // Check for valid Parent Phone
      if (parentPhone) {
        if (!phonePattern.test(parentPhone)) {
          rowErrors.parentPhone =
            "Invalid Parent Phone No. (must be 10 digits).";
        } else {
          // Track parent phone numbers and student names
          if (parentPhone in parentPhoneMapping) {
            parentPhoneMapping[parentPhone].push(studentFullName);
          } else {
            parentPhoneMapping[parentPhone] = [studentFullName];
          }
        }
      }
    }

    if (Object.keys(rowErrors).length > 0) {
      errors.push({ rowIndex: i, ...rowErrors });
    }
  }

  // Add messages for shared parent phone numbers
  Object.keys(parentPhoneMapping).forEach((phone) => {
    if (parentPhoneMapping[phone].length > 1) {
      errors.push({
        sharedParentPhone: `The phone number ${phone} is shared by students: ${parentPhoneMapping[
          phone
        ].join(", ")}.`,
      });
    }
  });

  return errors;
};



