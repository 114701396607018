import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import Swal from "sweetalert2";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import {
  createGroup,
  getGroups,
  getGroupById,
  updateGroupById,
  deleteGroupById,
} from "../../Services/CommonApis/GroupApi";
import { useParams } from "react-router-dom";

const GroupList = () => {
  const branchId = "";
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editGroupId, setEditGroupId] = useState(null);
  const [editGroupName, setEditGroupName] = useState("");

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteGroupById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The group has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the group.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The group is safe :)");
    }
  };

  const handleCreateGroup = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to create the group.`,
      "info",
      "Yes, Proceed",
      "No, cancel!"
    );
    if (result.isConfirmed) {
      showLoadingAlert();
    try {
      
      await createGroup({ name: values.name, branch_id: branchId });
      Swal.close();
      showSuccessAlert("Success!", "The Group has been created.");
      setShowCreateModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (err) {
      console.error("API error:", err);
      let errorMessage = "An unexpected error occurred. Please try again.";
      if (err.response) {
        errorMessage = err.response.data.error || err.response.data.message;
      } else if (err.request) {
        errorMessage = "No response from the server. Please try again.";
      }
      showErrorAlert("Error", errorMessage);
    }
  }else if (result.dismiss === Swal.DismissReason.cancel) {
    showErrorAlert("Cancelled", `The Group has not been created.`);
  }
  };

  const handleEdit = async (id) => {
    try {
      const response = await getGroupById(id);
      const { name } = response.data;
      setEditGroupId(id);
      setEditGroupName(name);
      setShowEditModal(true);
    } catch (err) {
      console.error("API error:", err);
      let errorMessage = "An unexpected error occurred. Please try again.";
      if (err.response) {
        errorMessage = err.response.data.error || err.response.data.message;
      } else if (err.request) {
        errorMessage = "No response from the server. Please try again.";
      }
      showErrorAlert("Error", errorMessage);
    }
  };

  const handleUpdateGroup = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to update the group.`,
      "info",
      "Yes, Proceed",
      "No, cancel!"
    );
    if (result.isConfirmed) {
      showLoadingAlert();
    try {
      await updateGroupById(editGroupId, {
        name: values.name,
        branch_id: branchId,
      });
      Swal.close();
      showSuccessAlert("Success!", "The group has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (err) {
      console.error("API error:", err);
      let errorMessage = "An unexpected error occurred. Please try again.";
      if (err.response) {
        errorMessage = err.response.data.error || err.response.data.message;
      } else if (err.request) {
        errorMessage = "No response from the server. Please try again.";
      }
      showErrorAlert("Error", errorMessage);
    }
  }else if (result.dismiss === Swal.DismissReason.cancel) {
    showErrorAlert("Cancelled", `The Group has not been updated.`);
  }
  };

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id",}, 
        { data: "name", }, 
        {
          data: "status",
          render: (data) => (data === "1" ? "Active" : "Inactive"),
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "inactive"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
        <div class="icon-group" style="display: flex; gap: 10px;">
          <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
          ${deleteIcon}
        </div>
      `;
          },
        },
      ],
      lengthChange: true,
      order:true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getGroups({
            _limit: length,
            _page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error(error);
          showErrorAlert(error.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, []);

  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Group name is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Group List</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ paddingTop: "5px" }} className="card-title">
                    Group List Table
                  </h3>
                  <button
                    className="btn btn-primary float-right"
                    onClick={() => setShowCreateModal(true)}
                  >
                    Create New
                  </button>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  >
                     <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </tfoot>
                    
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create Group Modal */}
        <Modal
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Group</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreateGroup}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="name">Group Name</label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter group name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowCreateModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>

        {/* Edit Group Modal */}
        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Group</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{ name: editGroupName }}
            validationSchema={validationSchema}
            onSubmit={handleUpdateGroup}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="name">Group Name</label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter group name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </section>
    </>
  );
};

export default GroupList;
