import React, { useState } from "react";
import TransportPilotDetails from "./TransportRoutes";
import TransportRoutesList from "./TransportRoutesList";

function TransportRouteManagement() {
  const [activeTab, setActiveTab] = useState("routelist");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "routelist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("routelist")}
            >
              Route List
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createroute" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createroute")}
            >
              Create Route
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === "routelist" ? "active" : ""}`}
            id="routelist"
          >
            {activeTab === "routelist" && <TransportRoutesList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createroute" ? "active" : ""
            }`}
            id="createroute"
          >
            {activeTab === "createroute" && <TransportPilotDetails />}
          </div>
        </div>
      </div>
    </>
  );
}

export default TransportRouteManagement;
