import axios from "axios";
import { v4 as uuid } from "uuid";
import { COOKIE } from "../Schemas/cookieNames";
import { getSession, removeAllSession } from "./Storage";
import { showLoginAlert } from "../Modals/SweetAlertModel";

const baseURL = process.env.REACT_APP_APIURL;

let setLoadingGlobal;

export const setGlobalLoading = (setLoading) => {
  setLoadingGlobal = setLoading;
};

const Api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en-us",
    "Access-Control-Allow-Origin": "*",
    "Platform-Type": "web",
  },
  timeout: 30000,
});

const shouldSendToken = (url) => {
  const whitelist = ["/auth/login"];
  return !whitelist.some((endpoint) => url.endsWith(endpoint));
};

const shouldSkipLoading = (url) => {
  const whitelist = [
    "dashboard/attendanceclass-count",
    "dashboard/homeworkclass-count",
    "dashboard/birthday-count",
    "get-languages",
    "get-user-details",
    "delete-lib-book",
  ];
  return whitelist.some((endpoint) => url.includes(endpoint));
};

const shouldSkipAcademicId = (url) => {
  const whitelist = [
    "login",
    "get-academics",
    "get-languages",
    "get-user-details",
    "get-dashboard-details",
    "attendanceclass-count",
    "dashboard/homeworkclass-count",
    "dashboard/birthday-count",
    "get-dist",
    "get-school",
    "get-states",
    "get-class"
  ];
  return !whitelist.some((endpoint) => url.endsWith(endpoint));
};

Api.interceptors.request.use(
  async (config) => {
    const token = getSession(COOKIE.ADMIN_TOKEN);
    const uniqueId = uuid();
    const academic_id = getSession(COOKIE.ACADEMIC_YEAR);

    if (shouldSendToken(config.url)) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization;
    }

    config.headers["X-REQUEST-ID"] = uniqueId;

    if (
      config.method === "get" &&
      setLoadingGlobal &&
      !shouldSkipLoading(config.url)
    ) {
      setLoadingGlobal(true);
    }

    if (
      config.method === "get" &&
      academic_id &&
      shouldSkipAcademicId(config.url)
    ) {
      config.params = {
        ...config.params,
        academic_id,
      };
    }

    return config;
  },
  (error) => {
    if (setLoadingGlobal) {
      setLoadingGlobal(false);
    }
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    if (setLoadingGlobal && !shouldSkipLoading(response.config.url)) {
      setLoadingGlobal(false);
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      showLoginAlert(
        "Session Expired",
        "Your session has expired due to inactivity. Please log in again.",
        "info",
        "Login"
      ).then((result) => {
        if (result.isConfirmed) {
          removeAllSession();
          window.location.reload();
        }
      });
    }
    if (setLoadingGlobal && !shouldSkipLoading(error.config.url)) {
      setLoadingGlobal(false);
    }
    console.error("API call error:", error);
    return Promise.reject(error);
  }
);

export default Api;