import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createClass } from "../../Services/CommonApis/classApi";
import { useParams } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";

const romanNumerals = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
];

const validationSchema = Yup.object({
  inputs: Yup.array().of(Yup.string().required("Class Name is required")),
});

const CreateClass = () => {
  const { branchId } = useParams();

  const getNextRoman = (roman) => {
    const index = romanNumerals.indexOf(roman);
    return index < romanNumerals.length - 1 ? romanNumerals[index + 1] : roman;
  };

  const getNextNumber = (num) => {
    return (parseInt(num, 10) + 1).toString();
  };

  const getNextInputValue = (lastValue) => {
    const trimmedValue = lastValue.trim();

    if (!trimmedValue) {
      return "";
    }

    const numberMatch = trimmedValue.match(/(\d+)$/);
    const romanMatch = trimmedValue.match(/(I|II|III|IV|V|VI|VII|VIII|IX|X)$/);

    if (numberMatch) {
      return trimmedValue.replace(
        numberMatch[0],
        getNextNumber(numberMatch[0])
      );
    } else if (romanMatch) {
      return trimmedValue.replace(romanMatch[0], getNextRoman(romanMatch[0]));
    }

    return "";
  };

  const handleSubmit = async (values) => {
    const formattedData = {
      class_name: values.inputs,
    };

    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the class",
      "info",
      "Yes, Create",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await createClass({
          branch_id: branchId,
          ...formattedData,
        });
        if (response.status === 201) {
          Swal.fire({
            title: "Created!",
            text: "Class created successfully.",
            icon: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          showErrorAlert(
            "Error!",
            response.data?.message || "Class creation failed. Please try again."
          );
        }
      } catch (err) {
        console.error("API error:", err);
        showErrorAlert(
          "Error",
          err.response?.data?.message ||
            "An unexpected error occurred. Please try again."
        );
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Class creation has been cancelled.");
    }
  };

  return (
    <>
      <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">
              Class Creation <small>Form</small>
            </h3>
          </div>

          <Formik
            initialValues={{
              inputs: [""],
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
              <div style={{ marginTop: "20px" }}>
                <div className="form-card">
                  <div className="card-body">
                    <label>
                      Class Name <span className="mandatory">*</span>
                    </label>
                    {values.inputs.map((input, index) => (
                      <div className="row" key={index}>
                        <div className="form-group col-6">
                          <Field
                            type="text"
                            name={`inputs.${index}`}
                            className="form-control"
                            placeholder={`Class Name ${index + 1}`}
                          />
                          <ErrorMessage
                            name={`inputs.${index}`}
                            component="div"
                            className="text-error"
                          />
                        </div>
                        <div className="form-group col-2 d-flex align-items-end">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              const newInputs = [
                                ...values.inputs,
                                getNextInputValue(
                                  values.inputs[values.inputs.length - 1]
                                ),
                              ];
                              setFieldValue("inputs", newInputs);
                            }}
                          >
                            +
                          </button>
                          {values.inputs.length > 1 && (
                            <button
                              type="button"
                              className="btn btn-danger ml-2"
                              onClick={() => {
                                const newInputs = values.inputs.filter(
                                  (_, i) => i !== index
                                );
                                setFieldValue("inputs", newInputs);
                              }}
                            >
                              -
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateClass;
