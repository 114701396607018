import React, { createContext, useContext, useState, useEffect } from 'react';
import { getSession } from '../../Services/Storage';
import { COOKIE } from '../../Schemas/cookieNames';
import { getAllAcademics } from '../../Services/AdminApis/AcademicApi';
import { getBranchBySchoolId } from '../../Services/CommonApis/branchApi';
import { getAllLanguages } from '../../Services/AdminApis/LanguagesApi';
import { getUserDetails } from '../../Services/CommonApis/userApi';
import { getUserId, getUserRoleId } from './extractUserDetailsFromToken';
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [academic, setAcademic] = useState([]);
  const [branches, setBranches] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [userData, setUserData] = useState({});
  const [roleId, setRoleId] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      const adminToken = getSession(COOKIE.ADMIN_TOKEN)
      setRoleId(getUserRoleId(adminToken))

      const academicResponse = await getAllAcademics();
      setAcademic(academicResponse.data.data);
      
      const schoolId = getSession(COOKIE.SCHOOL_ID);
      if (schoolId) {
        const branchesResponse = await getBranchBySchoolId(schoolId);
        setBranches(branchesResponse.branches);
      }

      const languageResponse = await getAllLanguages();
      setLanguages(languageResponse.data.data);

      const token = sessionStorage.getItem(COOKIE.ADMIN_TOKEN);
      if (token) {
        const response = await getUserDetails({ user_id: getUserId(token) })
        setUserData(response.data.data);
        setRoleId(getUserRoleId(token));
      }
    };

    fetchData();
  }, []);

  return (
    <DataContext.Provider value={{ academic, branches, languages, userData, roleId }}>
      {children}
    </DataContext.Provider>
  );
};
export const useData = () => useContext(DataContext);