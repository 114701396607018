import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { Modal, Button } from "react-bootstrap";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import {
  deleteSectionById,
  getAllSections,
  getSectionById,
  updateSectionById,
} from "../../Services/CommonApis/SectionApi";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import * as Yup from "yup";

const SectionList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { branchId, schoolId } = useParams();
  const [classList, setClassList] = useState([]);
  const [selectedClassId, setClassId] = useState(null);
  const [isClassLoading, setClassLoading] = useState(true);
  const [showTable, setShowTable] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editSectionId, setEditSectionId] = useState(null);
  const [editSectionName, setEditSectionName] = useState("");
  const [editClassId, setEditClassId] = useState(null);
  const [EditStatus, setEditStatus] = useState(null);

  useEffect(() => {
    fetchClassByBranchId(branchId);
  }, [branchId]);

  useEffect(() => {
    setShowTable(selectedClassId);
  }, [selectedClassId]);

  const fetchClassByBranchId = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
      setClassLoading(false);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete the section!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteSectionById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The section has been deleted.");

        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the section.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The section is safe 😊");
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await getSectionById(id);
      console.log("API Response:", response.data);

      const { name, class_id, status } = response.data.section;
      setEditSectionId(id);
      setEditSectionName(name);
      setEditClassId(class_id);
      setEditStatus(status);

      console.log("Class ID:", class_id);
      console.log("Status:", status);

      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching section details:", error);
      showErrorAlert(
        "Error!",
        "There was an error fetching the section details."
      );
    }
  };

  const handleUpdateSection = async (values) => {
    try {
      showLoadingAlert();
      await updateSectionById(editSectionId, {
        name: values.name,
        class_id: editClassId,
        status: EditStatus,
      });

      Swal.close();
      showSuccessAlert("Success!", "The section has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error updating the section.");
      console.error(error);
    }
  };

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        {
          data: "class_name",
        },
        { data: "name" },
        {
          data: "status",
          render: (data) => {
            const text = data === "1" ? "Active" : "Inactive";
            return `<div>${text}</div>`;
          },
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "1"
                ? `
                <a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
              `
                : "";
            return `
                <div class="icon-group" style="display: flex; gap: 10px;">
                  <a href="#" title="Edit" class="edit-icon" data-id="${row.id}"><i class="fas fa-edit"></i></a>
                  ${deleteIcon}
                </div>
              `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Section Data Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllSections({
            class_id: selectedClassId,
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });
          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });
    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      tableInstance.current.destroy();
    };
  }, [branchId, selectedClassId, showTable]);

  const initialValues = {
    class_id: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
  });

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Section List Table
          </h3>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, values }) => (
            <div className="card-body">
              <div className="row">
                <div className="form-group col-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="class_id"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const selectedClassId = e.target.value;
                      setFieldValue("class_id", selectedClassId);
                      setClassId(selectedClassId);
                    }}
                  >
                    {isClassLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Class</option>
                        {classList.map((cls) => (
                          <option key={cls.id} value={cls.id}>
                            {cls.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>
              </div>
              {showTable ? (
                <table
                  ref={tableRef}
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Class Name</th>
                      <th>Section Name</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <tfoot>
                    <tr>
                      <th>ID</th>
                      <th>Class Name</th>
                      <th>Section Name</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </tfoot>
                </table>
              ) : (
                <div className="alert alert-info">
                  Please select class to view the section list
                </div>
              )}
            </div>
          )}
        </Formik>
      </div>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Section</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ name: editSectionName }}
          validationSchema={Yup.object({
            name: Yup.string().required("Section name is required"),
          })}
          onSubmit={handleUpdateSection}
        >
          {({ handleSubmit, handleChange, values }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="name">Section Name</label>
                  <Field
                    name="name"
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={values.name}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Save Changes
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default SectionList;
