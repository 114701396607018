import React, { useState } from "react";
import SectionList from "../CommonPages/SectionList";
import SectionCreate from "../CommonPages/SectionCreate";
import SectionUpload from "./SectionUpload";

function SectionManagement() {
  const [activeTab, setActiveTab] = useState("sectionlist");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "sectionlist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("sectionlist")}
            >
              Section List
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createsection" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createsection")}
            >
              Create Section
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "uploadsection" ? "active" : ""
              }`}
              onClick={() => handleTabClick("uploadsection")}
            >
              Upload Section
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${
              activeTab === "sectionlist" ? "active" : ""
            }`}
            id="sectionlist"
          >
            {activeTab === "sectionlist" && <SectionList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createsection" ? "active" : ""
            }`}
            id="createsection"
          >
            {activeTab === "createsection" && <SectionCreate />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "uploadsection" ? "active" : ""
            }`}
            id="uploadsection"
          >
            {activeTab === "uploadsection" && <SectionUpload />}
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionManagement;
