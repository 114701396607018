import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getstudentbysectionIdBranchId } from "../../Services/CommonApis/StudentApi";
import {
  createBulkAttendance,
  getAttendanceByDate,
  getConsolidatedAttendance,
  getDaywiseAttendance,
} from "../../Services/AdminApis/AttendanceApi";
import { getClassSectionbyBranchId } from "../../Services/CommonApis/classApi";
import "primeicons/primeicons.css";
import BeatLoader from "react-spinners/BeatLoader";
import { Checkbox } from "primereact/checkbox";
import {
  showErrorAlert,
  showSuccessAlert,
  showLoadingAlert,
  showLoginAlert,
} from "../../Modals/SweetAlertModel";
import { COOKIE } from "../../Schemas/cookieNames";
import IndividualStudentAttendance from "./IndividualStudentAttendance";
import { useRef } from "react";
import { getSession } from "../../Services/Storage";
import { getUserEmail } from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import { useParams } from "react-router-dom";
import { CreateHoliday } from "../../Services/CommonApis/ParentPortalApi";
import Select from "react-select";
import { getAttendanceByDateSection } from "../../Services/AdminApis/AttendanceApi";

const AttendanceCreate = () => {
  const { branchId } = useParams();
  const [activeTab, setActiveTab] = useState("home");
  const [profileActiveTab, setProfileActiveTab] = useState("dayView");
  const [disabledSections, setDisabledSections] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [processedData, setProcessedData] = useState([]);
  const [dates, setDates] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [absenteesData, setAbsenteesData] = useState("");
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otp, setOtp] = useState(null);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [message, setMessage] = useState("");
  const [verifiedMessage, setVerifiedMessage] = useState("");
  const [formikValues, setFormikValues] = useState(null);
  const [otpToken, setOtpToken] = useState("");
  const setFieldValueRef = useRef(null);
  const [sectionStudents, setSectionStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState({});

  useEffect(() => {
    if (date) {
      const formattedDate = formatDate(date);
      fetchAttendedStudentsByDate(branchId, formattedDate);
    }
  }, [date]);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, 6);
    setEnteredOtp(pastedData.padEnd(6, ""));
  };

  const token = getSession(COOKIE.ADMIN_TOKEN);
  const email = getUserEmail(token);

  const Attendance = async (data) => {
    try {
      const response = await createBulkAttendance(data);
    } catch (error) {
      console.error("Error:", error.data.message);
      showErrorAlert("Error", error.data.message);
    }
  };

  const handleCheckboxChange = (e, rowData, values, setFieldValue) => {
    const updatedClassSections = values.classsections.map((item) =>
      item.section_id === rowData.section_id
        ? { ...item, selected: e.target.checked }
        : item
    );

    setFieldValue("classsections", updatedClassSections);

    const updatedSelectedSectionIds = e.target.checked
      ? [...(values.selectedSectionIds || []), rowData.section_id]
      : (values.selectedSectionIds || []).filter(
          (id) => id !== rowData.section_id
        );

    setFieldValue("selectedSectionIds", updatedSelectedSectionIds);

    if (e.target.checked) {
      fetchStudentsBySection(branchId, updatedSelectedSectionIds);
    } else {
      setSectionStudents([]);
      setSelectedStudents({});
    }
  };

  const createHoliday = async (data) => {
    try {
      const response = await CreateHoliday(data);
      showSuccessAlert();
    } catch (error) {
      console.error("Error:", error.data.message);
      showErrorAlert("Error", error.data.message);
    }
  };

  const fetchAttendedStudentsByDate = async (branchId, date) => {
    try {
      const attendanceData = await getAttendanceByDateSection(branchId, date);
      setDisabledSections(attendanceData.data);
      return attendanceData;
    } catch (error) {
      console.error("Error fetching attendance:", error);
    }
  };

  const fetchStudentsBySection = async (branchId, sectionId) => {
    try {
      const response = await getstudentbysectionIdBranchId(branchId, sectionId);
      setSectionStudents(response.data.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetchClassSection = async (branchId) => {
    try {
      const response = await getClassSectionbyBranchId(branchId);

      const data = response.data.data.map((item) => ({
        ...item,
        selected: false,
      }));

      setFieldValueRef.current("classsections", data);
    } catch (error) {
      console.error("Error fetching class sections:", error);
    }
  };

  useEffect(() => {
    fetchClassSection(branchId);
  }, []);

  const validationSchemaConsolidated = Yup.object().shape({
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("End date is required")
      .min(Yup.ref("start_date"), "End date cannot be before start date"),
  });

  const fetchConsolidatedAttendance = async (branchId, startDate, endDate) => {
    setLoading(true);
    try {
      const response = await getConsolidatedAttendance(
        branchId,
        startDate,
        endDate
      );
      const { processedData, dates } = processData(response.data);
      setProcessedData(processedData);
      setDates(dates);
    } catch (error) {
      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDaywiseAttendance = async (branchId, datewise) => {
    setLoading(true);

    try {
      const response = await getDaywiseAttendance(branchId, datewise);
      const data = response.data;

      const presentData = data.present_data || [];
      const absentData = data.absent_data || [];

      const combinedData = [...presentData, ...absentData].map((item) => ({
        studentName: item.student_name,
        className: item.class_name,
        date: item.attendance_date,
        status: presentData.some((p) => p.student_name === item.student_name)
          ? "Present"
          : "Absent",
      }));

      setAttendanceData(combinedData);
    } catch (error) {
      console.error("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const processData = (data) => {
    const processedData = [];
    const { present_data, absent_data } = data;

    const students = [
      ...new Set([
        ...present_data.map((d) => d.student_name),
        ...absent_data.map((d) => d.student_name),
      ]),
    ];
    const dates = [
      ...new Set([
        ...present_data.map((d) => d.attendance_date),
        ...absent_data.map((d) => d.attendance_date),
      ]),
    ];

    students.forEach((student) => {
      const studentClasses = [
        ...new Set([
          ...present_data
            .filter((p) => p.student_name === student)
            .map((p) => p.class_name),
          ...absent_data
            .filter((a) => a.student_name === student)
            .map((a) => a.class_name),
        ]),
      ];

      let studentRecord = {
        student_name: student,
        class_name: studentClasses[0] || "NA",
      };

      dates.forEach((date) => {
        studentRecord[date] = present_data.some(
          (p) => p.student_name === student && p.attendance_date === date
        )
          ? "present"
          : absent_data.some(
              (a) => a.student_name === student && a.attendance_date === date
            )
          ? "absent"
          : "NA";
      });

      processedData.push(studentRecord);
    });

    return { processedData, dates };
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    date: Yup.string().required("Date is required"),
  });

  const statusBodyTemplate = (rowData) => {
    const section = sectionStudents.find(
      (section) => section.section_id === rowData.section_id
    );

    const students = section
      ? section.students.map((student) => ({
          value: student.student_id,
          label: `${student.full_name}`,
        }))
      : [];

    const selectedValues = selectedStudents[rowData.section_id] || [];

    const handleSelectAllChange = (e) => {
      if (e.target.checked) {
        const allStudentIds = students.map((student) => student.value);
        handleStudentChange(rowData.section_id, allStudentIds);
      } else {
        handleStudentChange(rowData.section_id, []);
      }
    };

    const CustomMenuList = (props) => {
      const allSelected = selectedValues.length === students.length;

      return (
        <div style={{ maxHeight: 100, overflowY: "scroll" }}>
          <div
            style={{
              padding: "5px",
              borderBottom: "1px solid #ddd",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              checked={allSelected}
              onChange={handleSelectAllChange}
            />
            <p className="ml-2 mt-2">Select All</p>
          </div>
          {props.children}
        </div>
      );
    };

    const CustomOption = (props) => {
      const { isSelected, label, innerRef, innerProps } = props;

      return (
        <div
          ref={innerRef}
          {...innerProps}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "5px",
            cursor: "pointer",
            backgroundColor: isSelected ? "lightgray" : "white",
          }}
        >
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
            style={{ marginRight: "10px" }}
          />
          <p style={{ marginLeft: "5px", marginTop: "5px" }}>{label}</p>
        </div>
      );
    };

    return (
      <Select
        isMulti
        options={students}
        value={students.filter((student) =>
          selectedValues.includes(student.value)
        )}
        onChange={(selectedOptions) => {
          const selectedIds = selectedOptions.map((option) => option.value);
          handleStudentChange(rowData.section_id, selectedIds);
        }}
        placeholder="Select Students"
        components={{
          Option: CustomOption,
          MenuList: CustomMenuList,
        }}
        styles={{
          menu: (provided) => ({
            ...provided,
            maxHeight: 200,
            overflowY: "scroll",
          }),
        }}
      />
    );
  };

  const handleStudentChange = (sectionId, selectedIds) => {
    setSelectedStudents((prev) => ({
      ...prev,
      [sectionId]: selectedIds,
    }));
  };

  const dateTemplate = (rowData, column) => {
    const status = rowData[column.field];
    const isPresent = status === "present";
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          height: "40px",
          backgroundColor: isPresent ? "#e6ffe6" : "#ffe6e6",
          padding: "5px 15px",
          borderRadius: "5px",
        }}
      >
        <i
          className={`pi ${isPresent ? "pi-check" : "pi-times"}`}
          style={{ color: isPresent ? "green" : "red", marginRight: "5px" }}
        ></i>
        <p style={{ color: isPresent ? "green" : "red", margin: 0 }}>
          {isPresent ? "Present" : "Absent"}
        </p>
      </div>
    );
  };

  const validationDaywise = Yup.object({
    datewise: Yup.date().required("Date is required").nullable(),
  });

  const handleSubmit = async (values) => {
    const { datewise } = values;
    await fetchDaywiseAttendance(branchId, formatDate(datewise));
  };

  const handleAbsentSubmit = async (values) => {
    const { datewise } = values;
    await fetchAbsentees(branchId, formatDate(datewise));
  };

  const fetchAbsentees = async (branchId, datewise) => {
    setLoading(true);
    try {
      const response = await getDaywiseAttendance(branchId, datewise);
      const data = response.data;
      const absenteesData = (data.absent_data || []).map((item) => ({
        studentName: item.student_name,
        className: item.class_name,
      }));
      setAbsenteesData(absenteesData);
    } catch (error) {
      console.error("Error fetching absentees:", error);
    } finally {
      setLoading(false);
    }
  };

  const daywiseTemplate = (rowData) => {
    const isPresent = rowData.status === "Present";

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          width: "150px",
          height: "40px",
          backgroundColor: isPresent ? "#e6ffe6" : "#ffe6e6",
          padding: "5px 15px",
          borderRadius: "5px",
        }}
      >
        <i
          className={`pi ${isPresent ? "pi-check" : "pi-times"}`}
          style={{ color: isPresent ? "green" : "red", marginRight: "5px" }}
        ></i>
        <p style={{ color: isPresent ? "green" : "red", margin: 0 }}>
          {isPresent ? "Present" : "Absent"}
        </p>
      </div>
    );
  };

  const renderFullDay = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        width: "150px",
        height: "40px",
        backgroundColor: "#ffe6e6",
        padding: "5px 15px",
        borderRadius: "5px",
      }}
    >
      <i className="pi pi-times" style={{ color: "red" }}></i>
      <p style={{ color: "red", margin: 0 }}>Absent</p>
    </div>
  );

  const handleVerifyOTP = async () => {
    try {
      const response = await submitAttendance(
        formikValues,
        enteredOtp,
        otpToken
      );
      showSuccessAlert("Data has been submitted");
      window.location.reload();
    } catch (error) {
      console.error("Error during OTP verification:", error);
      setMessage("OTP verification failed. Please try again.");
    }
  };

  const generateOtpToken = () => {
    const characters =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const length = 10;
    let otpToken = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      otpToken += characters[randomIndex];
    }
    return otpToken;
  };

  //
  const submitAttendance = async (values, otp = null, otpToken) => {
    const attendanceData = values.classsections
      .filter((section) => selectedStudents[section.section_id]?.length > 0) // Filter only sections with absent students
      .map((section) => {
        const sectionId = section.section_id;
        const studentIds = selectedStudents[sectionId];

        return {
          class_id: section.class_id,
          section_id: sectionId,
          absentData: JSON.stringify(studentIds),
        };
      });

    const data = {
      branch_id: branchId,
      attendance: attendanceData,
      date: formatDate(values.date),
      otp_token: otpToken,
      otp: otp,
      notification: values.notification,
      notification_type: "Attendance",
      subject_id: "",
    };

    try {
      await Attendance(data);

      if (!otp) {
        setShowOtpPopup(true);
      } else {
        setShowOtpPopup(false);
        setDate("");
        setDates([]);
        setAttendanceData([]);
        setAbsenteesData("");
        setMessage("");
        setVerifiedMessage("");
        setOtp(null);
        setEnteredOtp("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSelectAll = (e, values, setFieldValue) => {
    const isChecked = e.target.checked;

    // Update all rows' selected state
    const updatedClassSections = values.classsections.map((item) => {
      const isDisabled = disabledSections.some(
        (section) => section.section_id === item.section_id
      );
      return {
        ...item,
        selected: isDisabled ? item.selected : isChecked,
      };
    });

    // Update all selected section IDs
    const updatedSelectedSectionIds = isChecked
      ? values.classsections
          .filter(
            (item) =>
              !disabledSections.some(
                (section) => section.section_id === item.section_id
              )
          )
          .map((item) => item.section_id)
      : [];

    setFieldValue("classsections", updatedClassSections);
    setFieldValue("selectedSectionIds", updatedSelectedSectionIds);
    if (e.target.checked) {
      fetchStudentsBySection(branchId, updatedSelectedSectionIds);
    } else {
      setSectionStudents([]);
      setSelectedStudents({});
    }
  };

  return (
    <div>
      <section id="model_5" className="card">
        <div className="card-header">
          <ul class="nav nav-pills">
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                onClick={() => setActiveTab("home")}
              >
                <i className="fas fa-user-clock mr-2"></i>Bulk Entry
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "individual" ? "active" : ""
                }`}
                onClick={() => setActiveTab("individual")}
              >
                <i className="fas fa-user-clock mr-1"></i> Individual Student
                Attendance
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "profile" ? "active" : ""
                }`}
                onClick={() => setActiveTab("profile")}
              >
                <i className="fas fa-user-check mr-1"></i>View Attendance
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "contact" ? "active" : ""
                }`}
                onClick={() => setActiveTab("contact")}
              >
                <i className="fas fa-user-times mr-1"></i> Holiday
              </a>
            </li>
          </ul>
        </div>
        <div
          className="attendance-tab-content container mt-5"
          id="myTabContent"
        >
          {activeTab === "individual" && <IndividualStudentAttendance />}
          {activeTab === "home" && (
            <>
              <Formik
                initialValues={{
                  date: date,
                  classsections: [],
                  notification: ["1", "2"],
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, formikHelpers) => {
                  try {
                    setFormikValues(values);
                    const otpToken = generateOtpToken();
                    setOtpToken(otpToken);

                    await submitAttendance(values, null, otpToken);
                  } catch (error) {
                    console.error("Error during submission:", error);
                    setMessage(
                      "There was an error submitting the attendance. Please try again."
                    );
                  }
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => {
                  setFieldValueRef.current = setFieldValue;
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div
                        className="d-flex flex-column justify-content-center  "
                        style={{
                          border: "1px solid skyblue",
                          marginTop: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "rgb(0, 123, 255)",
                            padding: "10px",
                          }}
                        >
                          <h5
                            style={{
                              marginTop: "0px",
                              color: "white",
                            }}
                          >
                            <i className="fas fa-calendar-check"></i> Add
                            Attendance
                          </h5>
                        </div>

                        <div className="d-flex flex-row justify-content-center mt-3 mb-3">
                          <div className="col-4">
                            <label htmlFor="date">
                              Select Date<span className="mandatory">* </span>{" "}
                            </label>
                            <Field
                              type="date"
                              name="date"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue("date", e.target.value);
                                setDate(e.target.value);
                              }}
                              style={{ borderColor: "skyblue" }}
                              max={new Date().toISOString().split("T")[0]}
                            />
                            <ErrorMessage
                              name="date"
                              component="div"
                              className="text-error"
                            />
                          </div>{" "}
                        </div>
                      </div>

                      <div className="card mt-5">
                        <DataTable
                          value={values.classsections || []}
                          className="custom-datatable1"
                        >
                          <Column
                            field="selected"
                            header={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  inputId="headerCheckbox"
                                  checked={
                                    values.classsections.length > 0 &&
                                    values.classsections
                                      .filter(
                                        (item) =>
                                          !disabledSections.some(
                                            (section) =>
                                              section.section_id ===
                                              item.section_id
                                          )
                                      )
                                      .every((item) => item.selected)
                                  } // Only consider non-disabled rows
                                  onChange={(e) =>
                                    handleSelectAll(e, values, setFieldValue)
                                  }
                                  style={{ marginRight: "8px" }}
                                />
                                Select Class
                              </div>
                            }
                            body={(rowData, { rowIndex }) => {
                              const isDisabled = disabledSections.some(
                                (section) =>
                                  section.section_id === rowData.section_id
                              );

                              return (
                                <Checkbox
                                  inputId={`checkbox_${rowIndex}`}
                                  checked={rowData.selected}
                                  disabled={isDisabled} // Disable checkbox if section_id matches
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      rowData,
                                      values,
                                      setFieldValue
                                    )
                                  }
                                />
                              );
                            }}
                            style={{ width: "150px" }}
                          />

                          <Column
                            field="class_data"
                            header="Class - Section Name"
                            body={(rowData) => {
                              const isDisabled = disabledSections.some(
                                (section) =>
                                  section.section_id === rowData.section_id
                              );

                              return (
                                <span
                                  style={{
                                    color: isDisabled ? "#999" : "inherit",
                                  }}
                                >
                                  {rowData.class_data || "N/A"}
                                </span>
                              );
                            }} // Fallback to N/A if not available
                            style={{ width: "250px" }}
                          ></Column>

                          <Column
                            field="first_name"
                            header="Add Absent Students"
                            body={(rowData) => {
                              const isDisabled = disabledSections.some(
                                (section) =>
                                  section.section_id === rowData.section_id
                              );

                              return (
                                <div
                                  style={{
                                    pointerEvents: isDisabled ? "none" : "auto",
                                    opacity: isDisabled ? 0.5 : 1,
                                  }}
                                >
                                  {statusBodyTemplate(rowData)}
                                </div>
                              );
                            }}
                            style={{ width: "250px" }}
                          ></Column>
                        </DataTable>
                      </div>
                      <div
                        className="d-flex flex-row justify-content-between align-items-center mt-3"
                        style={{
                          border: "1px solid skyblue",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          padding: "15px",
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontSize: "20px",
                          }}
                        >
                          Send By:
                        </p>
                        <div className="d-flex justify-content-around flex-grow-1 ml-3">
                          {[
                            {
                              id: "whatsapp-notification",
                              label: "WhatsApp",
                              value: "1",
                            },
                            {
                              id: "email-notification",
                              label: "Email",
                              value: "4",
                            },
                            {
                              id: "sms-notification",
                              label: "SMS",
                              value: "3",
                            },
                            {
                              id: "app-notification",
                              label: "App",
                              value: "2",
                            },
                          ].map((checkbox) => (
                            <div key={checkbox.id}>
                              <input
                                type="checkbox"
                                id={checkbox.id}
                                name="notification"
                                className="mr-2"
                                value={checkbox.value}
                                checked={values.notification.includes(
                                  checkbox.value
                                )}
                                onChange={(e) => {
                                  const { value, checked } = e.target;
                                  let newNotifications = [
                                    ...values.notification,
                                  ];

                                  if (checked) {
                                    newNotifications.push(value); // Add if checked
                                  } else {
                                    newNotifications = newNotifications.filter(
                                      (notification) => notification !== value
                                    );
                                  }

                                  setFieldValue(
                                    "notification",
                                    newNotifications
                                  );
                                }}
                              />
                              <label htmlFor={checkbox.id}>
                                {checkbox.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2 mt-3"
                          style={{
                            width: "100px",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              {showOtpPopup && (
                <>
                  <div
                    style={{
                      position: "fixed",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      backdropFilter: "blur(5px)", // Apply blur effect
                      zIndex: 1000, // Ensure it's above other content
                    }}
                  />
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "white",
                      padding: "20px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      zIndex: 1001,
                      borderRadius: "8px",
                      textAlign: "center",
                    }}
                  >
                    <div className="text-center">
                      <h5>
                        Please verify the attendance data
                        <br></br>
                        and enter the code sent to
                        <br></br>
                        {email}
                      </h5>
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                      {[...Array(6)].map((_, index) => (
                        <input
                          key={index}
                          type="text"
                          className="form-control mx-1 text-center"
                          style={{ width: "40px" }}
                          maxLength="1"
                          value={enteredOtp[index] || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            setEnteredOtp((prev) => {
                              const newOtp = prev.split("");
                              newOtp[index] = value;
                              return newOtp.join("");
                            });
                          }}
                          onPaste={handlePaste}
                        />
                      ))}
                    </div>
                    {/* {message && <p>{message}</p>} */}
                    <button
                      onClick={handleVerifyOTP}
                      className="btn btn-primary w-75"
                    >
                      Verify OTP
                    </button>
                    <button
                      onClick={() => setShowOtpPopup(false)}
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                    >
                      Close
                    </button>
                  </div>
                </>
              )}
            </>
          )}

          {activeTab === "profile" && (
            <>
              <ul
                className="custom-nav-tabs container"
                id="myTab"
                role="tablist"
              >
                <li className="custom-nav-item">
                  <a
                    className={`custom-nav-link ${
                      profileActiveTab === "dayView" ? "active" : ""
                    }`}
                    id="dayView-tab"
                    data-toggle="tab"
                    href="#dayView"
                    role="tab"
                    aria-controls="dayView"
                    aria-selected={activeTab === "dayView"}
                    onClick={() => setProfileActiveTab("dayView")}
                  >
                    <i className="fas fa-calendar-day"></i>
                    Day View
                  </a>
                </li>

                <li className="custom-nav-item">
                  <a
                    className={`custom-nav-link ${
                      profileActiveTab === "absentView" ? "active" : ""
                    }`}
                    id="absentView-tab"
                    data-toggle="tab"
                    href="#absentView"
                    role="tab"
                    aria-controls="absentView"
                    aria-selected={activeTab === "absentView"}
                    onClick={() => setProfileActiveTab("absentView")}
                  >
                    <i className="fas fa-user-times"></i>
                    Absent View
                  </a>
                </li>
                <li className="custom-nav-item">
                  <a
                    className={`custom-nav-link ${
                      profileActiveTab === "consolidatedView" ? "active" : ""
                    }`}
                    id="consolidatedView-tab"
                    data-toggle="tab"
                    href="#consolidatedView"
                    role="tab"
                    aria-controls="consolidatedView"
                    aria-selected={activeTab === "consolidatedView"}
                    onClick={() => setProfileActiveTab("consolidatedView")}
                  >
                    <i className="fas fa-file-signature"></i>
                    Consolidated View
                  </a>
                </li>
              </ul>
              {profileActiveTab === "dayView" && (
                <Formik
                  initialValues={{
                    datewise: new Date().toISOString().split("T")[0],
                  }}
                  validationSchema={validationDaywise}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue }) => (
                    <>
                      <Form>
                        <div
                          className="d-flex flex-column justify-content-center mt-3"
                          style={{
                            border: "1px solid skyblue",
                            marginTop: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "rgb(0, 123, 255)",
                              padding: "10px",
                            }}
                          >
                            <h5
                              style={{
                                marginTop: "0px",
                                color: "white",
                              }}
                            >
                              <i className="fas fa-list"></i> View Day wise
                              Attendance
                            </h5>
                          </div>
                          <div className="d-flex flex-row col-12 justify-content-center mt-3 mb-3">
                            <div className="col-4">
                              <label htmlFor="datewise">
                                Select Date<span className="mandatory">* </span>{" "}
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                id="datewise"
                                name="datewise"
                                placeholder="Enter date"
                                style={{ borderColor: "skyblue" }}
                                max={new Date().toISOString().split("T")[0]}
                              />
                              <ErrorMessage
                                name="datewise"
                                component="div"
                                style={{ color: "red" }}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mb-3">
                            <button type="submit" className="btn btn-primary">
                              search
                            </button>
                          </div>
                        </div>
                      </Form>

                      {loading ? (
                        <div className="text-center mt-3">
                          <BeatLoader color="#16dada" />
                        </div>
                      ) : (
                        <>
                          <div className="mt-3">
                            <DataTable
                              value={attendanceData}
                              paginator
                              rows={10}
                              className="p-datatable-striped"
                            >
                              <Column
                                field="className"
                                header="Class Name"
                              ></Column>
                              <Column
                                field="studentName"
                                header="Student Name"
                              ></Column>

                              <Column
                                header="Full Day"
                                body={daywiseTemplate}
                              ></Column>
                            </DataTable>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Formik>
              )}
              {profileActiveTab === "consolidatedView" && (
                <>
                  <Formik
                    initialValues={{
                      start_date: new Date().toISOString().split("T")[0],
                      end_date: "",
                    }}
                    validationSchema={validationSchemaConsolidated}
                    onSubmit={(values, { setSubmitting }) => {
                      const formatDate = (dateString) => {
                        const [year, month, day] = dateString.split("-");
                        return `${day}/${month}/${year}`;
                      };
                      setSubmitting(true);
                      fetchConsolidatedAttendance(
                        branchId,
                        formatDate(values.start_date),
                        formatDate(values.end_date)
                      );
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div
                          className="d-flex flex-column justify-content-center mt-3"
                          style={{
                            border: "1px solid skyblue",
                            marginTop: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "rgb(0, 123, 255)",
                              padding: "10px",
                            }}
                          >
                            <h5 style={{ marginTop: "0px", color: "white" }}>
                              <i className="fas fa-list"></i> View Consolidated
                              Attendance
                            </h5>
                          </div>
                          <div className="d-flex flex-row col-12 justify-content-center mt-3 mb-3">
                            <div className="col-4">
                              <label htmlFor="start_date">
                                From Date<span className="mandatory">* </span>
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                id="start_date"
                                name="start_date"
                                placeholder="Select from date"
                                style={{ borderColor: "skyblue" }}
                                max={new Date().toISOString().split("T")[0]}
                              />
                              <ErrorMessage
                                name="start_date"
                                component="div"
                                className="text-error"
                              />
                            </div>
                            <div className="col-4">
                              <label htmlFor="end_date">
                                To Date<span className="mandatory">* </span>
                              </label>
                              <Field
                                type="date"
                                className="form-control"
                                id="end_date"
                                name="end_date"
                                placeholder="Select to date"
                                style={{ borderColor: "skyblue" }}
                              />
                              <ErrorMessage
                                name="end_date"
                                component="div"
                                className="text-error"
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mb-3">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              search
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  {loading ? (
                    <div className="text-center mt-3">
                      <BeatLoader color="#16dada" />
                    </div>
                  ) : (
                    <>
                      <DataTable value={processedData} className="mt-3">
                        <Column field="class_name" header="Class Name" />
                        <Column field="student_name" header="Student Name" />
                        {dates.map((date, index) => {
                          const formattedDate = new Date(
                            date
                          ).toLocaleDateString("en-GB");

                          return (
                            <Column
                              key={index}
                              field={date}
                              header={formattedDate}
                              body={dateTemplate}
                            />
                          );
                        })}
                      </DataTable>
                    </>
                  )}
                </>
              )}
              {profileActiveTab === "absentView" && (
                <>
                  <Formik
                    initialValues={{
                      datewise: new Date().toISOString().split("T")[0],
                    }}
                    validationSchema={validationDaywise}
                    onSubmit={handleAbsentSubmit}
                  >
                    {({ values, setFieldValue, errors, touched }) => (
                      <Form
                        className="d-flex flex-column justify-content-center mt-3"
                        style={{
                          border: "1px solid skyblue",
                          marginTop: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "rgb(0, 123, 255)",
                            padding: "10px",
                          }}
                        >
                          <h5 style={{ marginTop: "0px", color: "white" }}>
                            <i className="fas fa-list"></i> View Absentees list
                          </h5>
                        </div>
                        <div
                          className="col-4 mt-3 mb-3"
                          style={{ margin: "auto" }}
                        >
                          <label htmlFor="datewise">
                            Select Date<span className="mandatory">* </span>
                          </label>
                          <Field
                            type="date"
                            className={`form-control ${
                              errors.datewise && touched.joining_date
                                ? "is-invalid"
                                : ""
                            }`}
                            id="datewise"
                            name="datewise"
                            placeholder=""
                            style={{ borderColor: "skyblue" }}
                            max={new Date().toISOString().split("T")[0]}
                          />
                          {errors.datewise && touched.datewise && (
                            <div className="invalid-feedback">
                              {errors.datewise}
                            </div>
                          )}
                        </div>

                        <div className="text-center mb-3">
                          <button type="submit" className="btn btn-primary">
                            Search
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  {loading ? (
                    <div className="text-center mt-3">
                      <BeatLoader color="#16dada" />
                    </div>
                  ) : (
                    <>
                      <div className="mt-4">
                        <DataTable
                          value={absenteesData}
                          paginator
                          rows={10}
                          className="p-datatable-sm"
                        >
                          <Column
                            field="className"
                            header="Class Name"
                            sortable
                          />
                          <Column
                            field="studentName"
                            header="Student Name"
                            sortable
                          />
                          <Column body={renderFullDay} header="Full Day" />
                        </DataTable>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {activeTab === "contact" && (
            <Formik
              initialValues={{
                holiday_date: new Date().toISOString().split("T")[0],
                name: "",
              }}
              validationSchema={Yup.object({
                holiday_date: Yup.date().required("Date is required"),
                name: Yup.string().required("Message is required"),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  await createHoliday(values);
                  resetForm();
                } catch (error) {
                  console.error("Error:", error.data.message);
                  showErrorAlert("Error", error.data.message);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form
                  onSubmit={handleSubmit}
                  className="d-flex flex-column justify-content-center"
                  style={{
                    border: "1px solid skyblue",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgb(0, 123, 255)",
                      padding: "10px",
                    }}
                  >
                    <h5 style={{ marginTop: "0px", color: "white" }}>
                      <i className="fas fa-calendar-check"></i> Add Holiday
                    </h5>
                  </div>

                  <div className="d-flex flex-row justify-content-center mt-3 mb-3">
                    <div className="col-4">
                      <label htmlFor="holiday_date">
                        Select Date<span className="mandatory">* </span>
                      </label>
                      <Field
                        type="date"
                        className="form-control"
                        id="holiday_date"
                        name="holiday_date"
                        style={{ borderColor: "skyblue" }}
                        min={new Date().toISOString().split("T")[0]}
                      />
                      <ErrorMessage
                        name="holiday_date"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div
                    className="form-group col-4 mt-3"
                    style={{ margin: "auto" }}
                  >
                    <label htmlFor="name">
                      Message <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter Message"
                      style={{ borderColor: "skyblue" }}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary mb-2 mt-3"
                    style={{
                      width: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </section>
    </div>
  );
};

export default AttendanceCreate;
