import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { getSchools } from "../../Services/AdminApis/schoolApi";
import { getBranchBySchoolId } from "../../Services/CommonApis/branchApi";
import { deleteLibraryBookById, getLibraryBooksList } from "../../Services/AdminApis/LibraryApi";
import AdminBookEdit from "./AdminBookEdit";

const AdminBooksList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [schoolList, setSchoolList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [bookEdit, setBookEdit] = useState(false);
  const [isSchoolLoading, setIsSchoolLoading] = useState(true);
  const [isBranchLoading, setIsBranchLoading] = useState(null);

  useEffect(() => {
    fetchSchoolList();
  }, []);

  useEffect(() => {
    if (selectedSchoolId) {
      setIsBranchLoading(true);
      fetchBranchBySchoolId(selectedSchoolId);
      setSelectedBranchId(null);
    }
  }, [selectedSchoolId]);

  useEffect(() => {
    setShowTable(selectedSchoolId && selectedBranchId);
  }, [selectedSchoolId, selectedBranchId]);

  const fetchSchoolList = async () => {
    try {
      setIsSchoolLoading(true);
      const response = await getSchools();
      setSchoolList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSchoolLoading(false);
    }
  };

  const fetchBranchBySchoolId = async (schoolId) => {
    try {
      setIsBranchLoading(true);
      const response = await getBranchBySchoolId(schoolId);
      setBranchList(response.branches);
    } catch (error) {
      console.log(error);
    } finally {
      setIsBranchLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete the book!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteLibraryBookById(id);
        showSuccessAlert("Deleted!", "The book has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        showErrorAlert("Error!", "There was an error deleting the book.");
        console.error(error);
      } finally {
        Swal.close();
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The book is safe 😊");
    }
  };

  const [bookId, setBookId] = useState(null);

  useEffect(() => {
    if (showTable) {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "id", title: "S.No" },
          { data: "name", title: "Name" },
          { data: "title", title: "Title" },
          { data: "description", title: "Description" },
          { data: "price", title: "Price" },
          { data: "author", title: "Author" },
          { data: "publisher", title: "Publisher" },
          { data: "display_name", title: "Display Name" },
          { data: "published_date", title: "Published Date" },

          {
            data: "status",
            title: "Status",
            render: (data) => (data === "1" ? "Active" : "Inactive"),
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
                <a href="" title="Edit" data-id=${row.id} class="edit-icon"><i class="fas fa-edit"></i></a>
              </div>
            `,
          },
        ],
        lengthChange: true,
        order:true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Books Data Found",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "desc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getLibraryBooksList({
              branch_id: selectedBranchId,
              page: page,
              _limit: length,
              _sort: orderColumn,
              _order: orderDirection,
              q: searchValue,
            });

            const jsonData = response.data.books || [];
            const totalCount = response.data.total || 0;

            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });

      $(tableRef.current).on("click", ".delete-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        handleDelete(id);
      });

      $(tableRef.current).on("click", ".edit-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        setBookId(id);
        setBookEdit(true);
      });

      return () => {
        if (tableInstance.current) {
          tableInstance.current.destroy(true);
        }
      };
    }
  }, [selectedSchoolId, selectedBranchId, showTable]);

  const initialValues = {
    class_id: "",
    section_id: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
  });

  return (
    <>
      {bookEdit ? (
        <AdminBookEdit bookId={bookId}/>
      ) : (
        <div className="card">
          <div className="card-header">
            <h3 style={{ paddingTop: "5px" }} className="card-title">
              Library Books List
            </h3>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ setFieldValue, values }) => (
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-4">
                    <label htmlFor="class_id">
                      School Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="class_id"
                      name="class_id"
                      className="form-control"
                      onChange={(e) => {
                        const selectedSchoolId = e.target.value;
                        setFieldValue("class_id", selectedSchoolId);
                        setSelectedSchoolId(selectedSchoolId);
                      }}
                    >
                      {isSchoolLoading ? (
                        <option value="" disabled>
                          Loading...
                        </option>
                      ) : (
                        <>
                          <option value="">Select Class</option>
                          {schoolList.map((school) => (
                            <option key={school.id} value={school.id}>
                              {school.name}
                            </option>
                          ))}
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="class_id"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="form-group col-4">
                    <label htmlFor="section_id">
                      Branch Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="section_id"
                      name="section_id"
                      className="form-control"
                      value={selectedBranchId || ""}
                      onChange={(e) => {
                        const selectedBranchId = e.target.value;
                        setFieldValue("section_id", selectedBranchId);
                        setSelectedBranchId(selectedBranchId);
                      }}
                      disabled={!selectedSchoolId}
                    >
                      {isBranchLoading ? (
                        <option value="" disabled>
                          Loading...
                        </option>
                      ) : (
                        <>
                          <option value="">Select Section</option>
                          {branchList.map((branch) => (
                            <option key={branch.id} value={branch.id}>
                              {branch.branch_name}
                            </option>
                          ))}
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="section_id"
                      component="div"
                      className="text-error"
                    />
                  </div>
                </div>

                {showTable ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table
                          ref={tableRef}
                          className="table table-striped table-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Title</th>
                              <th>Description</th>
                              <th>Price</th>
                              <th>Author</th>
                              <th>Publisher</th>
                              <th>Display Name</th>
                              <th>Published Date</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                          <tfoot>
                            <tr>
                            <th>S.No</th>
                              <th>Name</th>
                              <th>Title</th>
                              <th>Description</th>
                              <th>Price</th>
                              <th>Author</th>
                              <th>Publisher</th>
                              <th>Display Name</th>
                              <th>Published Date</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="alert alert-info">
                    Please select both school and branch to view the Books
                    list.
                  </div>
                )}
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default AdminBooksList;
