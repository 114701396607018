import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import {
  DeleteCertificateType,
  UpdateCertificateType,
  createCertificateType,
  GetCertificateType,
  GetSingleCertificateType,
  getCertificateFieldsByType,
} from "../../Services/AdminApis/CertificateApi";

const CertificateConfiguration = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editGroupId, setEditGroupId] = useState(null);
  const [editGroupName, setEditGroupName] = useState("");
  const [fields, setFields] = useState([]);

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await DeleteCertificateType(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The group has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        // showErrorAlert("Error!", "There was an error deleting the group.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The group is safe :)");
    }
  };

  const handleCreateCertificate = async (values) => {
    try {
      showLoadingAlert();
      await createCertificateType({
        certificate_type: values.certificate_type,
      });
      Swal.close();
      showSuccessAlert("Success!", "The Group has been created.");
      setShowCreateModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFields = async (id) => {
    try {
      const response = await getCertificateFieldsByType(id);
      console.log("niiii", response.data.certificate_field);
      setFields(response.data.certificate_field);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await GetSingleCertificateType(id);
      setEditGroupId(id);
      setEditGroupName(response.data.certificate.certificate_type);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching group details:", error);
    }
  };

  const handleUpdateCertificate = async (values) => {
    try {
      showLoadingAlert();
      await UpdateCertificateType(editGroupId, {
        certificate_type: values.certificate_type,
      });
      Swal.close();
      showSuccessAlert("Success!", "The group has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  // Initialize the DataTable
  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        {
          data: null,
          title: "S.No",
          render: (data, type, row, meta) => {
            return meta.row + 1;
          },
        },
        { data: "certificate_type", title: "Certificate Name" },
        {
          data: "fields",
          title: "Fields",
          render: (data) => {
            // If data is null or an empty array, return "No fields"
            if (data && data.length > 0) {
              return data
                .map(
                  (field) => `<span class="bg-primary" style="
          color: white;
          padding: 5px 10px;
          font-size: 14px;
          border-radius: 15px;
          margin-right: 5px;
          display: inline-block;
          white-space: nowrap;
        ">${field}</span>`
                )
                .join(" ");
            } else {
              return `<span style="color: gray;">No fields</span>`;
            }
          },
        },
        {
          data: null,
          title: "Actions",
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            return `
            <div class="icon-group" style="display: flex; gap: 10px;">
              <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
              <a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
            </div>
          `;
          },
        },
      ],
      lengthChange: true,
      order:true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },

      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await GetCertificateType({
            _limit: length,
            _page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.certificate_types;
          const totalCount = jsonData.length;

          console.log(jsonData, "bad day early morning");

          const dataWithFields = await Promise.all(
            jsonData.map(async (item) => {
              try {
                const fieldsResponse = await getCertificateFieldsByType(
                  item.id
                );
                console.log(fieldsResponse, "bad day");

                const fields = (
                  fieldsResponse.data.certificate_fields || []
                ).map((field) => field.field_label);
                console.log(fields, "jiii");

                return {
                  ...item,
                  fields,
                };
              } catch (error) {
                console.error(
                  `Error fetching fields for certificate ${item.id}:`,
                  error
                );

                return {
                  ...item,
                  fields: [],
                };
              }
            })
          );

          console.log(dataWithFields, "bad day early morning");

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: dataWithFields,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    // Event listeners for edit and delete buttons
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, []);

  const initialValues = {
    certificate_type: "",
  };

  const validationSchema = Yup.object({
    certificate_type: Yup.string().required("Certificate name is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Certificate List</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ paddingTop: "5px" }} className="card-title">
                    Certificate List
                  </h3>
                  <button
                    className="btn btn-primary float-right"
                    onClick={() => setShowCreateModal(true)}
                  >
                    Create New
                  </button>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Certificate</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreateCertificate}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="name">Certificate Name</label>
                    <Field
                      type="text"
                      name="certificate_type"
                      className="form-control"
                      placeholder="Enter certificate name"
                    />
                    <ErrorMessage
                      name="certificate_type"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowCreateModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>

        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Certificate</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{ certificate_type: editGroupName }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleUpdateCertificate}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="name">Certificate Name</label>
                    <Field
                      type="text"
                      name="certificate_type"
                      className="form-control"
                      placeholder="Enter certificate name"
                    />
                    <ErrorMessage
                      name="certificate_type"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </section>
    </>
  );
};

export default CertificateConfiguration;
